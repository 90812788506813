import PlusIcon from "iconComponents/PlusIcon";
import TrashIcon from "iconComponents/TrashIcon";
import React from "react";
import { useState } from "react";
import Select, { components } from "react-select";
import {
  Card,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import "./Quiz.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { GrSearch } from "react-icons/gr";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import api2 from "api2";
import { useHistory, useParams } from "react-router";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { MdOutlineDragIndicator } from "react-icons/md";
import { ClassNames } from "@emotion/react";
import classNames from "classnames";
import ConfirmationModel from "components/Common/ConfirmationModel";
const customStyles = {
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
};
const AddQuiz = () => {
  const { questionList } = useSelector((state) => state?.quiz);
  const { _id } = useParams();
  const history = useHistory();
  const [quizName, setQuizName] = useState('');
  const [allQuestions, setQuestions] = useState();
  const [searchQuestion, setSearchQuestion] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [questionInfo, setQuestionInfo] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedQuestionList, setSelectedQuestionList] = useState([]);
  const [isDraft, setIsDraft] = useState();
  const [isValidFields, setIsValidFields] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isError, setIsError] = useState();
  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  // const [isValidQuestion, setIsValidQuestion] = useState();
  useEffect(() => {
    let quizArray = [];
    if (questionList?.length) {
      questionList.map((i) => {
        let matchQuiz = selectedQuestionList.find((v) => v?.value === i?._id);
        if (!matchQuiz) {
          quizArray.push({
            label: i.question,
            value: i._id,
          });
        }
      });
      setQuestions(quizArray);
    }
  }, [selectedQuestionList]);
  useEffect(() => {
    const questionArray = questionList?.map((i) => {
      return {
        label: i.question,
        value: i._id,
      };
    });
    setQuestions(questionArray);
  }, [questionList]);
  const fetchCourseExam = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };

      const result = await api2.get(`/api/course/exam/${_id}`, config);
      setQuizName(result.data?.data.name);
      const selectedQuestionArray = result.data?.data.mcqs.map(
        (item, index) => {
          return {
            id: index,
            label: item?.questionId?.question,
            value: item?.questionId?._id,
            marks: item?.marks,
          };
        }
      );
      setSelectedQuestionList(selectedQuestionArray);
      setIsDraft(result.data?.data?.isDraft);
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };
  const addQuestionInfo = () => {
    let selectedValue = questionList?.find(
      (i) => i._id === selectedQuestion?.value
    );

    setQuestionInfo(selectedValue);
  };
  useEffect(() => {
    addQuestionInfo();
  }, [selectedQuestion]);

  useEffect(() => {
    if (_id) {
      fetchCourseExam();
    }
  }, []);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <GrSearch />
      </components.DropdownIndicator>
    );
  };
  const handleAddQuestion = () => {
    isValidationFuc();
    if (!isError && selectedQuestion?.marks > 0) {
      if (!selectedQuestion.value) {
        setIsValidFields({
          ...isValidFields,
          hasQuestionList: true,
          hasQuizName: allQuestions && allQuestions,
        });
        setIsError(true);
        return;
      }
      setSelectedQuestionList([
        ...selectedQuestionList,
        { ...selectedQuestion, id: selectedQuestionList.length },
      ]);
      setSelectedIndex('');
      setSelectedQuestion('');
      setSearchQuestion('');
      setIsValidFields();
      setIsError(false);
    } else {
      setIsError(true);
    }
  };
  const handleEditQuestion = () => {
    if (!isError) {
      selectedQuestionList.splice(selectedIndex - 1, 1, {
        ...selectedQuestion,
      });
      setSelectedQuestionList(selectedQuestionList);
      setSelectedIndex('');
      setSelectedQuestion('');
      setSearchQuestion('');
    }
  };
  const handleRemove = () => {
    selectedQuestionList.splice(selectedIndex - 1, 1);
    setSelectedQuestionList(selectedQuestionList);
    setSelectedIndex(selectedQuestionList?.length);
    setSelectedQuestion(selectedQuestionList[selectedQuestionList?.length - 1]);
  };
  const saveQuiz = async (isDraft) => {
    isValidationFuc();
    if (!quizName) {
      return;
    }
    if (!selectedQuestionList?.length) {
      setAlert({
        ...alert,
        isOpenAlert: true,
        status: 'warning',
        message: 'Please Add a Question',
      });
      return;
    }
    if (quizName) {
      const mcqs = selectedQuestionList.map((item) => {
        return {
          questionId: item.value,
          marks: item.marks,
        };
      });
      let payload = {
        mcqs,
        isDraft,
        listType: 'quiz',
        name: quizName,
      };
      try {
        const config = {
          headers: { Authorization: token },
        };
        if (!_id) {
          await api2.post(`/api/course/exam`, payload, config);
        } else {
          await api2.put(`/api/course/exam/${_id}`, payload, config);
        }

        setAlert({
          ...alert,
          isOpenAlert: true,
          status: 'success',
          message: _id
            ? 'Quiz updated successfully!'
            : 'Quiz added successfully!',
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          throw new Error('Cancelled');
        }
      }
    }
  };
  const handleSave = async () => {
    if (selectedQuestionList.length) {
      setIsDisabled(true);
      let isDraft = true;
      saveQuiz(isDraft);
    } else {
      setAlert({
        ...alert,
        isOpenAlert: true,
        status: 'warning',
        message: 'Please Add a Question',
      });
    }
  };
  const questionPublish = async () => {
    if (selectedQuestionList.length) {
      setIsDisabled(true);
      setAlert({
        ...alert,
        isOpenAlert: true,
        status: 'warning',
        message: 'Are you sure you want to publish',
      });
    } else {
      setAlert({
        ...alert,
        isOpenAlert: true,
        status: 'warning',
        message: 'Please Add a Question',
      });
    }
  };
  const itemRenderer = (item, index) => {
    return (
      <div
        className="question_list_box"
        onClick={() => {
          setSelectedIndex(index + 1);
          setSearchQuestion('');
          setSelectedQuestion(item);
        }}
      >
        <MdOutlineDragIndicator />
        <span className="question_list_number">Q{index + 1}</span>
        <span className="question_list_title">{item?.label}</span>
        <span className="question_list_number">{item?.marks || 0}</span>
      </div>
    );
  };
  const handleRLDDChange = (reorderedItems) => {
    setSelectedQuestionList(reorderedItems);
  };
  const isValidationFuc = () => {
    let hasQuizName = false;
    let hasQuestionList = false;
    let hasQuestionMark = false;
    if (!quizName) {
      hasQuizName = true;
    }
    if (!selectedQuestionList?.length) {
      hasQuestionList = true;
    }
    if (!selectedQuestionList[selectedIndex]?.marks) {
      hasQuestionMark = true;
    }
    let obj = {
      ...isValidFields,
      hasQuizName,
      hasQuestionList,
      hasQuestionMark,
    };
    setIsValidFields(obj);
    setIsError(true);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card className="p-lg-4 ">
                <div className="add_course_page_header">
                  <div className="d-flex gap-3 align-items-center">
                    <h5 className="org_page_title">
                      {!_id ? 'Add' : 'Edit'} Quiz
                    </h5>
                    {isDraft && <span className="draft_badge">Draft</span>}
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <button
                      className="cancel_form_btn"
                      onClick={() => history.push('/quiz')}
                    >
                      Cancel
                    </button>
                    {isDraft !== false && (
                      <button
                        disabled={isDisabled}
                        className="draft_form_btn"
                        onClick={handleSave}
                      >
                        Save Draft
                      </button>
                    )}
                    <button
                      disabled={isDisabled}
                      className="publish_form_btn"
                      onClick={questionPublish}
                    >
                      Publish
                    </button>
                  </div>
                </div>

                <Form role="form" className="add_quiz_form ">
                  <div className="question_list_wrapper p-3 mb-5">
                    <FormGroup className="">
                      <label className="org_form_label my-2 mx-0">
                        Quiz Name
                      </label>
                      <Input
                        placeholder="Your Quiz Name"
                        type="text"
                        autoComplete="off"
                        name="questionTitle"
                        className="org_form_input"
                        value={quizName}
                        valid={
                          quizName &&
                          isValidFields &&
                          !isValidFields.hasQuizName
                        }
                        invalid={
                          !quizName &&
                          isValidFields &&
                          isValidFields.hasQuizName
                        }
                        onChange={(e) => {
                          setQuizName(e.target.value);
                          // isValidationFuc();
                        }}
                      />
                    </FormGroup>
                  </div>
                  <Row className="">
                    <div className="col-md-4 my-2">
                      <div className="question_list_wrapper">
                        <div className="d-flex align-items-center justify-content-between p-3">
                          <span className="question_list_box_title">
                            Question
                          </span>
                          <span className="question_list_box_subtitle">
                            Total: {selectedQuestionList?.length}
                          </span>
                        </div>
                        {selectedQuestionList?.length ? (
                          <>
                            <div className="question_list_box_wrapper px-3 mr-2 pb-3">
                              <RLDD
                                cssClasses="list-container"
                                items={selectedQuestionList}
                                itemRenderer={itemRenderer}
                                onChange={handleRLDDChange}
                              />
                              {/* {selectedQuestionList?.map((item, index) => (
                                <div
                                  className="question_list_box"
                                  onClick={() => {
                                    setSelectedIndex(index + 1);
                                    setSearchQuestion("");
                                    setSelectedQuestion(item);
                                  }}
                                >
                                  <span className="question_list_number">
                                    Q{index + 1}
                                  </span>
                                  <span className="question_list_title">
                                    {item?.label}
                                  </span>
                                </div>
                              ))} */}
                            </div>
                          </>
                        ) : (
                          <div className="d-flex justify-content-center">
                            Not Question Found
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-8 my-2 form-content-wrapper-main">
                      <FormGroup className="w-100">
                        <label className="org_form_label mb-2 mx-0">
                          Search Question
                        </label>
                        <Select
                          name="colors"
                          styles={customStyles}
                          components={{
                            DropdownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Search Question"
                          options={allQuestions}
                          className="basic-multi-select"
                          classNamePrefix="select question_select"
                          value={searchQuestion}
                          onChange={(searchValue) => {
                            setSearchQuestion(searchValue);
                            setSelectedQuestion(searchValue);
                          }}
                        />
                      </FormGroup>
                      {/* Section */}
                      <div className="form-content-wrapper">
                        <div className="form-content-head">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="form-content-count">
                              {selectedIndex ||
                                selectedQuestionList?.length + 1}
                            </span>
                            <span className="form-content-title">Question</span>
                          </div>
                          {selectedQuestionList?.length > 1 && (
                            <div
                              className="form-content-remove cursor-pointer"
                              type="button"
                              onClick={handleRemove}
                            >
                              <TrashIcon color={'#EF466F'} />
                            </div>
                          )}
                        </div>
                        <FormGroup className="w-90">
                          <Select
                            //   defaultValue={[colourOptions[2], colourOptions[3]]}
                            // isMulti
                            name="colors"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            className={classNames({
                              'basic-multi-select': true,
                              'is-error':
                                isValidFields &&
                                isValidFields?.hasQuestionList &&
                                !selectedQuestion.value,
                              'is-success':
                                isValidFields &&
                                !isValidFields?.hasQuestionList &&
                                selectedQuestion.value,
                            })}
                            styles={customStyles}
                            placeholder="Question"
                            options={allQuestions}
                            // className="basic-multi-select"
                            classNamePrefix="select"
                            value={selectedQuestion}
                            onChange={(searchValue) => {
                              setSearchQuestion('');
                              setSelectedQuestion(searchValue);
                              setIsValidFields({
                                ...isValidFields,
                                hasQuestionList: false,
                              });
                            }}
                          />
                        </FormGroup>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                          <div className="d-flex gap-4 flex-wrap">
                            <div className="d-flex flex-column">
                              <span className="que-type-title">Complexity</span>
                              <span className="que-type-content">
                                {questionInfo?.difficulty}
                              </span>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="que-type-title">Category</span>
                              <span className="que-type-content">
                                {questionInfo?.questionCategoryId?.name}
                              </span>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="que-type-title">Tags</span>
                              <span className="que-type-content">
                                {questionInfo?.tags?.join(', ')}
                              </span>
                            </div>
                          </div>

                          <FormGroup className="">
                            <label className="org_form_label mb-2 mx-0">
                              Enter Marks
                            </label>
                            <Input
                              placeholder="Marks"
                              type="number"
                              min="1"
                              max="100"
                              name="questionTitle"
                              className="org_form_input"
                              // className={classNames({
                              //   "basic-multi-select": true,
                              //   "is-error":
                              //     isValidFields &&
                              //     isValidFields?.hasQuestionList &&
                              //     !selectedQuestion,
                              //   "is-success":
                              //     isValidFields &&
                              //     !isValidFields?.hasQuestionList &&
                              //     selectedQuestion,
                              // })}
                              valid={selectedQuestion?.marks}
                              invalid={isError && !selectedQuestion?.marks}
                              value={selectedQuestion?.marks || ''}
                              // valid={isValidFields && !isValidFields.hasQuizName}
                              // invalid={isValidFields && isValidFields.hasQuizName}
                              onChange={(e) => {
                                const value = e.target.value;
                                setIsError(false);

                                if (value === '') {
                                  // Handle the case where the field is empty (e.g., user deleted the input)
                                  setSelectedQuestion({
                                    ...selectedQuestion,
                                    marks: '',
                                  });
                                  setIsError(true);
                                } else if (parseInt(value) <= 100) {
                                  // Update only if the value is within the allowed range
                                  setSelectedQuestion({
                                    ...selectedQuestion,
                                    marks: parseInt(value),
                                  });
                                }

                                // Update the field validity
                                setIsValidFields({
                                  ...isValidFields,
                                  hasQuestionMark: value !== '', // Consider it valid if there's some content
                                });
                              }}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      {selectedIndex ? (
                        <>
                          <div
                            className="add_new_input w-100"
                            onClick={handleEditQuestion}
                          >
                            <PlusIcon color={'#070707'} />
                            <span className="add_new_title disabled">
                              Edit Question
                            </span>
                          </div>
                        </>
                      ) : (
                        <div
                          className="add_new_input w-100"
                          onClick={handleAddQuestion}
                        >
                          <PlusIcon color={'#070707'} />
                          <span className="add_new_title disabled">
                            Add Question
                          </span>
                        </div>
                      )}
                    </div>
                  </Row>
                </Form>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      {alert.status === 'success' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpenAlert}
          buttonText={'OK'}
          confirmation={() => {
            setAlert({ isOpenAlert: false });
            history.push('/quiz');
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
        />
      )}
      {alert.status === 'warning' && alert.isOpenAlert && (
        <ConfirmationModel
          status={alert.status}
          isOpen={alert.isOpenAlert}
          confirmation={() => {
            let isDraft = false;
            saveQuiz(isDraft);
          }}
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onClose={() => {
            setAlert({ isOpenAlert: false });
          }}
        />
      )}
    </>
  );
};

export default AddQuiz;
