import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import Dropzone from "dropzone";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DocIcon from "../../../assets/img/icons/common/icon-doc.svg";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
  Input,
  FormGroup,
} from "reactstrap";
import {
  updateUserOrganisationPassword,
  updateUsers,
  uploadCsv,
} from "actions/adminActions/usersActions";
import { loaderType } from "../../../type";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import Select from "react-select";
import { CSVLink } from "react-csv";
import CategoriesAltImg from "../../../assets/img/brand/categories.png";

import { getAllOrgUser } from "actions/adminActions/organisationsActions";
import classNames from "classnames";
import NewCustomDropzon from "../../../components/Common/NewCustomDropzon";
import InfoIcon from "iconComponents/InfoIcon";
import { useSelector } from "react-redux";

Dropzone.autoDiscover = false;

const ImportUsers = ({ getOrganizationsAction, uploadCsvAction }) => {
  const history = useHistory();
  const csvData = [];
  const headers = [
    { label: "name", key: "name" },
    { label: "email", key: "email" },
    { label: "password", key: "password" },
  ];
  const [organizations, setOrganizations] = useState([]);
  const [excelFile, setExcelFile] = useState("");
  const [selectedOrg, setSelectedOrg] = useState();
  const [alert, setAlert] = useState({
    alert: false,
    alertStatus: "",
    alertMsg: "",
  });
  const { orgData } = useSelector((state) => state?.organisation);
  useEffect(() => {
    getOrganizationsAction();
  }, []);
  useEffect(() => {
    let formatOrgData =
      orgData.length &&
      orgData.map((item) => {
        return {
          label: item?.name,
          value: item?._id,
        };
      });
    setOrganizations(formatOrgData);
  }, [orgData]);
  const uploadCSV = () => {
    if (!selectedOrg) {
      setAlert({
        alert: true,
        alertStatus: "warning",
        alertMsg: "Please Select Organisation.",
      });
      return;
    }
    const uploadedFile = excelFile;

    const isCsvFile =
      uploadedFile.type === "application/vnd.ms-excel" ||
      uploadedFile.type === "text/csv" ||
      uploadedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (isCsvFile) {
      let uploadcsv = {
        file: uploadedFile,
        orgid: selectedOrg,
      };
      uploadCsvAction(uploadcsv)
        .then((res) => {
          setAlert({
            alert: true,
            alertStatus: "success",
            alertMsg: "User imported successfully.",
          });
          setExcelFile("");
        })
        .catch((err) => {
          setAlert({
            alert: true,
            alertStatus: "warning",
            alertMsg: err ? "User Already Exists." : err,
          });
        });
    } else {
      setAlert({
        alert: true,
        alertStatus: "warning",
        alertMsg: "Please upload valid CSV file.",
      });
    }
  };

  const closeAlert = () => {
    setAlert({
      ...alert,
      alert: false,
      alertStatus: "",
      alertMsg: "",
    });
  };
  const handleConfirmation = () => {
    history.push("/users");
  };
  const handleUploadExcel = (file) => {
    setExcelFile(file);
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 45,
      minHeight: 45,
    }),
  };
  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "n/a";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + " " + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  }
  return (
    <>
      {alert && alert.alertStatus === 'success' ? (
        <SweetAlert
          success
          title={alert.alertMsg}
          onConfirm={handleConfirmation}
        />
      ) : alert.alertStatus === 'warning' ? (
        <SweetAlert warning title={alert.alertMsg} onConfirm={closeAlert} />
      ) : null}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">Import Users</h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button className="publish_form_btn" onClick={uploadCSV}>
                        Import Users
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" noValidate>
                    <Row className="">
                      <div className="col-md-7">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Organisations
                          </label>
                          <Select
                            name="selectExam"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            styles={customStyles}
                            placeholder="Select"
                            options={organizations}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            onChange={(newValue) => {
                              setSelectedOrg(newValue?.value);
                            }}
                          />
                          {/* <div className="valid-feedback">Looks good!</div> */}
                        </FormGroup>
                      </div>

                      <div className="col-md-7">
                        <FormGroup className="">
                          <label className="add_course_form_label my-2 mx-0">
                            Upload Users
                          </label>

                          <NewCustomDropzon
                            name="iconImage"
                            accept=" image/jpeg, image/jpg, image/png"
                            handleUpload={handleUploadExcel}
                            type="document"
                          />

                          <div className="d-flex gap-2 my-2">
                            <InfoIcon color={'#070707'} />
                            <span
                              className={classNames({
                                add_course_form_sublabel1: true,
                              })}
                            >
                              Only .csv format is supported, download sample
                              format{' '}
                              <CSVLink
                                filename="template"
                                data={csvData}
                                headers={headers}
                              >
                                <span style={{ color: 'var(--primary)' }}>
                                  here
                                </span>
                              </CSVLink>
                            </span>
                          </div>
                          {excelFile && (
                            <div className="add_course_form_img_preview_wrapper">
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  className="add_course_form_preview_img"
                                  src={DocIcon}
                                  alt=""
                                />

                                <div className="d-flex flex-column">
                                  <span className="add_course_form_img_preview_name">
                                    {excelFile?.name}
                                  </span>
                                  <span className="add_course_form_img_preview_type">
                                    {excelFile.name.split('.')[1] === 'csv' &&
                                      'CSV'}
                                  </span>
                                </div>
                              </div>

                              <span className="add_course_form_img_preview_size">
                                {excelFile?.size &&
                                  bytesToSize(excelFile?.size)}
                              </span>
                            </div>
                          )}
                        </FormGroup>

                        {/* <label style={{ fontSize: 12, fontStyle: "italic" }}>
                          Only .csv format is supported, download sample format
                          -
                          <CSVLink
                            filename="template"
                            data={csvData}
                            headers={headers}
                          >
                            here
                          </CSVLink>
                        </label> */}
                      </div>
                    </Row>
                    {/* <Button
                        color="primary"
                        type="button"
                        onClick={this.uploadCSV}
                      >
                        Import Users
                      </Button> */}
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    challenges: state.challenges,
    organizations: state.organisation.orgData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadCsvAction: (file) =>
      trackPromise(dispatch(uploadCsv(file)), loaderType.GENERAL_REQUEST),
    // updateUserAction: (data, id) =>
    //   trackPromise(dispatch(updateUsers(data, id)), loaderType.GENERAL_REQUEST),
    // updateUserPasswordAction: (id, password) =>
    //   trackPromise(
    //     dispatch(updateUserOrganisationPassword(id, password)),
    //     loaderType.GENERAL_REQUEST
    //   ),
    getOrganizationsAction: () =>
      trackPromise(dispatch(getAllOrgUser(0, 200)), loaderType.GENERAL_REQUEST),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportUsers);
