import API from 'api2';

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};

export const createTag = (state) => {
  return async (dispatch, getState) => {
    const token = getState().authState.user.accessToken;

    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/tag/add`,
        headers: config(token),
        data: {
          name: state.name,
          description: state.description,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'CREATE_TAG_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errData =
            (err.response && err.response.data) || 'Unknown Error Occured';
          dispatch({ type: 'CREATE_TAG_ERROR' }, err);
          reject({ success: false, message: errData.message });
        });
    });
  };
};

export const editTag = (tagId, state) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'put',
        url: `api/tag/${tagId}`,
        headers: config(token),
        data: {
          name: state.name,
          description: state.description,
          tabAccess: state.tabAccess,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_TAG_SUCCESS' });
          resolve({ success: true, data });
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({ type: 'UPDATE_TAG_ERROR' }, err);
          reject({ success: false, message: errMessage });
        });
    });
  };
};
export const deleteTag = (tagId) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'delete',
        url: `api/tag/${tagId}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({ type: 'DELETE_TAG_SUCCESS' });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: 'DELETE_TAG_ERROR' }, err);
          reject();
        });
    });
  };
};
export const tagDetails = (tagId) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise(function (resolve, reject) {
      API({
        method: 'get',
        url: `api/tag/${tagId}`,
        headers: config(token),
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const getAllTag = (skip, limit) => {
  return (dispatch, getState) => {
    const token = getState().authState.user.accessToken;
    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `api/tag/list`,
        headers: config(token),
      })
        .then((res) => {
          const tagList = res.data.data;
          dispatch({
            type: 'FETCH_ALL_TAG_SUCCESS',
            payload: true,
            data: tagList,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'FETCH_ALL_TAG_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};
