import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
} from "reactstrap";
import { useHistory } from "react-router";
import { createOrganisation } from "actions/adminActions/organisationsActions";
import SweetAlert from "react-bootstrap-sweetalert";
import { hideAlert, showAlert } from "actions/templateAction";
import { trackPromise } from "react-promise-tracker";
import { loaderType } from "type";
import Select from "react-select";
import ReactQuill from "react-quill";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { createAnnouncement } from "actions/announcement";
import { getAnnouncement } from "actions/announcement";
import moment from "moment";
import ClassNames from "classnames"
import dayjs from "dayjs";
import "./ManageAnnouncement.css"


const Announcements = ({ addAnnouncement, getSingleAnnouncement, profile, announcement }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isDisable, setIsDisable] = useState(announcement?.status || "disabled")
    const [isShowError, setIsShowError] = useState()
    const [alert, setAlert] = useState({
        alert: false,
        alertStatus: "",
        alertMsg: ""
    })
    const [announcementInfo, setAnnouncementInfo] = useState(announcement)
    useEffect(() => {
      if (announcement) {
        setIsDisable(announcement?.status === 'published' ? true : false);
        setAnnouncementInfo(announcement);
      }
    }, [announcement]);

    const usersType = [
      {
        label: 'All',
        value: 'all',
      },
      {
        label: 'Organisation',
        value: 'organisation',
      },
      {
        label: 'Individual',
        value: 'individual',
      },
    ];
    const statusType = [
      {
        label: 'Draft',
        value: 'draft',
      },
      {
        label: 'Publish',
        value: 'publish',
      },
    ];
    const handleAnnouncement = (name, value) => {
      setAnnouncementInfo({
        ...announcementInfo,
        [name]: value,
      });
    };
    useEffect(() => {
      getSingleAnnouncement(profile?._id);
    }, []);
    const handlerSave = async () => {
      try {
        setIsShowError(true);
        if (
          announcementInfo?.title &&
          announcementInfo?.description &&
          announcementInfo?.description?.replace(/<(.|\n)*?>/g, '').trim()
            .length !== 0 &&
          announcementInfo?.userType &&
          announcementInfo?.status
        ) {
          await addAnnouncement(announcementInfo);
          setAlert({
            alert: true,
            alertStatus: 'success',
            alertMsg: 'Announcement save successfully.',
          });
          getSingleAnnouncement(profile?._id);
        }
      } catch (err) {
        setAlert({
          alert: true,
          alertStatus: 'warning',
          alertMsg: err ? err : '',
        });
      }
    };
    const handleConfirm = () => {
      setAlert({
        ...alert,
        alert: false,
        alertStatus: '',
        alertMsg: '',
      });
    };
    return (
      <>
        <SimpleHeader name="Add User" parentName="Users" />
        {alert?.alertStatus === 'success' && alert && (
          <SweetAlert
            success
            title={alert?.alertMsg}
            onConfirm={handleConfirm}
          />
        )}
        {alert?.alertStatus === 'warning' && alert && (
          <SweetAlert
            warning
            title={alert?.alertMsg}
            onConfirm={handleConfirm}
          />
        )}
        <Container className="" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <div className="add_course_page_header">
                      <div className="d-flex gap-3 align-items-center">
                        <h5 className="org_page_title">Manage Announcement</h5>
                        {announcement?.status === 'disabled' && (
                          <span className="draft_badge error_badge">
                            {' '}
                            Disabled
                          </span>
                        )}
                        {announcement?.status === 'published' && (
                          <span className="draft_badge success_badge">
                            {' '}
                            Enabled
                          </span>
                        )}
                        {moment(announcement?.startDate).isAfter() ? (
                          <>
                            <span className="draft_badge success_badge">
                              {' '}
                              Coming soon{' '}
                            </span>
                          </>
                        ) : (
                          <>
                            {moment(announcement?.expiryDate).isBefore() && (
                              <>
                                <span className="draft_badge error_badge">
                                  {' '}
                                  Expired{' '}
                                </span>
                              </>
                            )}{' '}
                          </>
                        )}
                      </div>
                      <div className="d-flex gap-3 align-items-center">
                        {/* <input id="toggle-on" class="toggle toggle-left" name="toggle" value="false" type="radio" checked={!isDisable} onClick={() => setIsDisable(false)} />
                                            <label for="toggle-on" class="btn">Enable</label>
                                            <input id="toggle-off" class="toggle toggle-right" name="toggle" value="true" type="radio" checked={isDisable} onClick={() => setIsDisable(true)} />
                                            <label for="toggle-off" class="btn">Disable</label> */}
                        <button
                          className="publish_form_btn"
                          onClick={() => handlerSave()}
                        >
                          Save
                        </button>

                        <div className="announcement-custom-checkbox">
                          <input
                            id="announcement-status"
                            type="checkbox"
                            name="announcement-status"
                            checked={isDisable}
                            onChange={(e) => {
                              let status = '';
                              if (e.target.checked) {
                                status = 'published';
                              } else {
                                status = 'disabled';
                              }
                              setIsDisable(e.target.checked);
                              handleAnnouncement('status', status);
                            }}
                          />

                          <label for="announcement-status">
                            <div
                              className="announcement-status-switch"
                              data-unchecked="Disabled"
                              data-checked="Enable"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <Row>
                        <Col className="" md="6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Title
                            </label>
                            <Input
                              placeholder="Enter Announcement title"
                              type="text"
                              autoComplete="off"
                              className="add_course_form_input"
                              name="title"
                              value={announcementInfo?.title}
                              valid={isShowError && announcementInfo?.title}
                              invalid={isShowError && !announcementInfo?.title}
                              onChange={(e) =>
                                handleAnnouncement('title', e.target.value)
                              }
                            />
                          </FormGroup>

                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Call to Action
                            </label>
                            <Input
                              placeholder="Enter Announcement Action Title"
                              type="text"
                              autoComplete="off"
                              className="add_course_form_input"
                              name="title"
                              value={announcementInfo?.actionTitle}
                              onChange={(e) =>
                                handleAnnouncement(
                                  'actionTitle',
                                  e.target.value
                                )
                              }
                            />
                          </FormGroup>

                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Link
                            </label>
                            <Input
                              placeholder="Enter Announcement Link"
                              type="link"
                              autoComplete="off"
                              className="add_course_form_input"
                              name="link"
                              value={announcementInfo?.link}
                              onChange={(e) =>
                                handleAnnouncement('link', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col className="" md="6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Description
                            </label>
                            <ReactQuill
                              className="react_quill"
                              theme="snow"
                              placeholder="Enter announcement details.."
                              value={announcementInfo?.description}
                              onChange={(value) =>
                                handleAnnouncement('description', value)
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col className="" md="6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Start Date
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DateTimePicker']}>
                                <DateTimePicker
                                  inputFormat="MM/DD/YYYY hh:mm a"
                                  defaultValue={dayjs(
                                    announcementInfo?.startDate
                                  )}
                                  onChange={(selectedDate) =>
                                    handleAnnouncement(
                                      'startDate',
                                      selectedDate?.$d
                                    )
                                  }
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </FormGroup>
                        </Col>
                        <Col className="" md="6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Expiry Date
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DateTimePicker']}>
                                <DateTimePicker
                                  inputFormat="MM/DD/YYYY hh:mm a"
                                  minDate={dayjs(
                                    announcementInfo?.startDate
                                  ).add(1, 'minute')}
                                  defaultValue={dayjs(
                                    announcementInfo?.expiryDate
                                  )}
                                  onChange={(selectedDate) =>
                                    handleAnnouncement(
                                      'expiryDate',
                                      selectedDate?.$d
                                    )
                                  }
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </FormGroup>
                        </Col>
                        <Col className="" md="6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Banner Color
                            </label>
                            <Input
                              type="color"
                              autoComplete="off"
                              className="add_course_form_input"
                              name="title"
                              value={announcementInfo?.bannerColor}
                              onChange={(e) =>
                                handleAnnouncement(
                                  'bannerColor',
                                  e.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="" md="6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Title Color
                            </label>
                            <Input
                              type="color"
                              autoComplete="off"
                              className="add_course_form_input"
                              name="title"
                              value={announcementInfo?.titleColor}
                              onChange={(e) =>
                                handleAnnouncement('titleColor', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="" md="6">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Content Color
                            </label>
                            <Input
                              type="color"
                              autoComplete="off"
                              className="add_course_form_input"
                              name="title"
                              value={announcementInfo?.textColor}
                              onChange={(e) =>
                                handleAnnouncement('textColor', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
};
const mapStateToProps = (state) => {
    return {
        profile: state.authState?.user,
        announcement: state?.courses?.announcement,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // updateCourseAction: (data, id) =>
        //     trackPromise(
        //         dispatch(updateCourse(data, id)),
        //         loaderType.GENERAL_REQUEST
        //     ),
        getSingleAnnouncement: (id) => dispatch(getAnnouncement(id)),
        addAnnouncement: (data) => dispatch(createAnnouncement(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
