import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createTag,
  editTag,
  getAllTag,
  tagDetails,
} from 'actions/adminActions/tagAction';
import { hideAlert, showAlert } from 'actions/templateAction';
import SweetAlert from 'react-bootstrap-sweetalert';
import { trackPromise } from 'react-promise-tracker';
import ReactQuill from 'react-quill';
import { useHistory, useParams } from 'react-router';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { loaderType } from 'type';
const AddTag = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);
  const [state, setState] = useState({
    name: '',
    nameErr: null,
    description: '',
    descriptionErr: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(tagDetails(id)).then((res) => {
        setState({
          ...state,
          name: res.name,
          description: res.description,
        });
      });
    }
  }, [id]);

  const validateCustomStylesForm = async () => {
    let isValid = true;
    let newState = { ...state };

    if (!newState.name) {
      newState.nameErr = 'invalid';
      isValid = false;
    } else {
      newState.nameErr = 'valid';
    }

    setState(newState);

    if (isValid) {
      if (!id) {
        trackPromise(dispatch(createTag(state)), loaderType.GENERAL_REQUEST)
          .then((res) => {
            dispatch(showAlert(true, 'success', res.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err.message));
          });
      } else {
        trackPromise(dispatch(editTag(id, state)), loaderType.GENERAL_REQUEST)
          .then((res) => {
            dispatch(showAlert(true, 'success', res?.data?.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err.message));
          });
      }
    }
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = { ...state };
    newState[name] = value;
    if (value === '') {
      newState[name + 'Err'] = 'invalid';
    } else {
      newState[name + 'Err'] = 'valid';
    }
    setState(newState);
  };

  const navigateTo = () => (type === 'success' ? history.push('/tags') : null);
  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo();
    }
  };

  return (
    <>
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">
                        {!id ? 'Add' : 'Edit'} Tag
                      </h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push('/tags')}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        onClick={validateCustomStylesForm}
                      >
                        {id ? 'Update' : 'Add'} Tag
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Name
                          </label>
                          <Input
                            value={state.name}
                            className="org_form_input"
                            name="name"
                            placeholder="Name"
                            type="text"
                            autoComplete="off"
                            valid={state.nameErr === 'valid'}
                            invalid={state.nameErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Description
                          </label>
                          <ReactQuill
                            theme="snow"
                            placeholder="Add description.."
                            value={state?.description}
                            onChange={(value) => {
                              setState({
                                ...state,
                                description: value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddTag;
