import { Checkbox } from '@mui/material';
import {
  getLabApi,
  getMCQApi,
  updateCourse,
} from 'actions/adminActions/courseAction';
import api2 from 'api2';
import axios from 'axios';
import classNames from 'classnames';
import NewCustomDropzon from 'components/Common/NewCustomDropzon';
import { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CiEdit } from 'react-icons/ci';
import { MdVideoFile } from 'react-icons/md';
import { trackPromise } from 'react-promise-tracker';
import ReactQuill from 'react-quill';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Select from 'react-select';
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import { loaderType } from 'type';
import DoubleLeftIcon from '../../../assets/img/icons/common/icon-double-chevron-left.svg';
import DoubleRightIcon from '../../../assets/img/icons/common/icon-double-chevron-right.svg';
import InfoIcon from '../../../assets/img/icons/common/icon-info.svg';
import PlusIcon from '../../../assets/img/icons/common/icon-plus.svg';
import TrashIcon from '../../../assets/img/icons/common/icon-trash.svg';
import ContentPanel from './ContentPanel';

const mediaOptions = [
  { value: 'video', label: 'Video' },
  { value: 'doc', label: 'Document' },
  { value: 'text', label: 'Text' },
  { value: 'mcq', label: 'Quiz' },
  { value: 'lab', label: 'Challenge' },
];

const mediaTypeExtensions = {
  video: ['mp4', 'mkv', 'mpeg'],
  doc: ['pdf', 'jpg', 'jpeg', 'png'],
};
const mediaTypeExtensionsAccept = {
  video: '.mkv, video/mp4, video/*, video/mkv, video/mpeg',
  doc: 'application/pdf, image/jpeg, image/png',
};
const CourseContent = ({
  addCourse,
  setAddCourse,
  getMCQApi,
  getLabApi,
  mcqList,
  challengesList,
  setPageView,
  updateCourseAction,
}) => {
  const { id } = useParams();
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const [uploadType, setUploadType] = useState('link');
  const [mcqLists, setMcqLists] = useState([]);
  const [labLists, setLabLists] = useState([]);
  const [preview, setPreview] = useState();
  const [isSectionValid, setIsSectionValid] = useState(true);
  const [isLectureValid, setIsLectureValid] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedDeleteType, setSelectedDeleteType] = useState('');
  const [quillChange, setQuillChange] = useState(false);
  const [isStartProgress, setIsStartProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpen: false,
  });
  const [selectedContent, setSelectedContent] = useState({
    section: '',
    lecture: '',
  });

  useEffect(() => {
    setIsDisabled(true);
  }, [selectedIndex]);

  useEffect(() => {
    getMCQApi();
    getLabApi();
    setSelectedContent({
      section: addCourse?.courseContents[0],
      lecture: addCourse?.courseContents[0].contents[0],
    });
  }, []);

  useEffect(() => {
    setMcqLists(mcqList.map((i) => ({ label: i.question, value: i._id })));
  }, [mcqList]);

  useEffect(() => {
    setLabLists(challengesList.map((i) => ({ label: i.title, value: i._id })));
  }, [challengesList]);

  const handleAddSection = async () => {
    if (selectedContent?.section.title && selectedContent?.lecture.title) {
      setIsSectionValid(true);
      setIsLectureValid(true);
    } else {
      setIsSectionValid(!!selectedContent?.section.title);
      setIsLectureValid(!!selectedContent?.lecture.title);
      return;
    }

    const newSection = {
      title: '',
      _id: addCourse.courseContents.length + 1,
      contents: [{ _id: 0, title: '', duration: 0, link: '', type: 'video' }],
    };

    if (id) {
      try {
        const config = { headers: { Authorization: token } };
        const payload = { title: '', type: 'video', link: '', duration: 0 };
        const result = await api2.post(`api/courseContent`, payload, config);
        newSection.contents[0] = result.data.data;
      } catch (error) {
        if (axios.isCancel(error)) throw new Error('Cancelled');
      }
    }

    addCourse.courseContents.push(newSection);
    setSelectedContent({
      section: newSection,
      lecture: newSection.contents[0],
    });
    setAddCourse({ ...addCourse });
  };

  const handleSelectSection = (section, lecture) => {
    setQuillChange(false);
    setIsSectionValid(true);
    setIsLectureValid(true);
    setSelectedContent({ section, lecture });
  };

  const handleChangeSection = (name, value) => {
    const selectedSectionIndex = addCourse.courseContents.findIndex((x) =>
      x.contents.find((i) => i._id === selectedContent?.lecture._id)
    );
    addCourse.courseContents[selectedSectionIndex][name] = value;
    setAddCourse({ ...addCourse });
  };

  const handleChangeContents = (name, value) => {
    const selectedSectionIndex = addCourse.courseContents.findIndex((x) =>
      x.contents.find((i) => i._id === selectedContent?.lecture._id)
    );
    const selectedLectureIndex = selectedContent?.section?.contents.findIndex(
      (x) => x._id === selectedContent?.lecture?._id
    );
    addCourse.courseContents[selectedSectionIndex].contents[
      selectedLectureIndex
    ][name] = value;
    setSelectedContent({
      ...selectedContent,
      lecture:
        addCourse.courseContents[selectedSectionIndex].contents[
          selectedLectureIndex
        ],
    });
    setAddCourse({ ...addCourse });
  };

  const handleDelete = async () => {
    const type = selectedDeleteType;
    const selectedSectionIndex = addCourse.courseContents.findIndex(
      (x) => x._id === selectedContent?.section?._id
    );
    const selectedLectureIndex = selectedContent?.section?.contents.findIndex(
      (x) => x._id === selectedContent?.lecture?._id
    );

    if (type === 'section') {
      addCourse.courseContents.splice(selectedSectionIndex, 1);
      setSelectedContent({
        ...selectedContent,
        section: addCourse.courseContents[0],
      });
    } else {
      const removeContent =
        addCourse.courseContents[selectedSectionIndex].contents[
          selectedLectureIndex
        ];
      if (id) {
        try {
          const config = { headers: { Authorization: token } };
          await api2.delete(
            `api/courseContent/deletePermanent/${removeContent?._id}`,
            config
          );
        } catch (error) {
          if (axios.isCancel(error)) throw new Error('Cancelled');
        }
      }
      addCourse.courseContents[selectedSectionIndex].contents.splice(
        selectedLectureIndex,
        1
      );
      setSelectedContent({
        ...selectedContent,
        lecture: addCourse.courseContents[selectedSectionIndex].contents[0],
      });
    }

    setAddCourse({ ...addCourse });
    updateCourseAction(addCourse, id);
    setAlert({ ...alert, isOpen: false });
  };

  const handleRemove = (type) => {
    setSelectedDeleteType(type);
    setAlert({
      message: 'Are you sure you want to delete?',
      status: 'warning',
      isOpen: true,
    });
  };

  const handleSaveChange = async () => {
    if (!selectedContent?.section.title || !selectedContent?.lecture.title) {
      setIsSectionValid(!!selectedContent?.section.title);
      setIsLectureValid(!!selectedContent?.lecture.title);
      return;
    }

    const selectedSectionIndex = addCourse.courseContents.findIndex(
      (x) => x._id === selectedContent?.section?._id
    );
    const selectedLectureIndex = selectedContent?.section?.contents.findIndex(
      (x) => x._id === selectedContent?.lecture?._id
    );
    const courseContent =
      addCourse.courseContents[selectedSectionIndex].contents[
        selectedLectureIndex
      ];

    if (courseContent?._id) {
      try {
        const config = { headers: { Authorization: token } };
        await api2.put(
          `api/courseContent/${courseContent?._id}`,
          courseContent,
          config
        );
        updateCourseAction(addCourse, id);
        setAlert({
          isOpen: true,
          status: 'success',
          message: 'Course updated successfully',
        });
      } catch (error) {
        if (axios.isCancel(error)) throw new Error('Cancelled');
      }
    }
  };

  const handleBack = () => setPageView('courseDetail');
  const handleContinue = () => setPageView('publish');
  const handleConfirm = () => setAlert({ ...alert, isOpen: false });

  const handleUpload = async (file, name) => {
    try {
      setIsStartProgress(true);
      const config = {
        headers: { Authorization: token },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      };
      const formData = new FormData();
      formData.append('file', file);
      const result = await api2.post(
        `api/courseContent/upload`,
        formData,
        config
      );
      handleChangeContents('link', result?.data?.data?.fileName);
      setIsStartProgress(false);
    } catch (error) {
      if (axios.isCancel(error)) throw new Error('Cancelled');
    }
    setPreview(file);
  };

  const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
  };

  const handleEditSection = () => setIsDisabled(!isDisabled);

  return (
    <>
      <Form role="form" className="add_course_form">
        <Row>
          <div className="col-md-4">
            <div className="course-content-wrapper">
              <span>Content</span>
              <div className="course-section-wrapper">
                <div className="accordion-item course-section-list">
                  {addCourse.courseContents.map((item, index) => (
                    <ContentPanel
                      key={index}
                      item={item}
                      selectedContent={selectedContent}
                      setIsSectionValid={setIsSectionValid}
                      setIsLectureValid={setIsLectureValid}
                      index={index}
                      setAddCourse={setAddCourse}
                      addCourse={addCourse}
                      handleSelectSection={handleSelectSection}
                      setSelectedIndex={setSelectedIndex}
                    />
                  ))}
                </div>
              </div>
              <button
                className="add-section-wrapper"
                type="button"
                onClick={handleAddSection}
              >
                <img src={PlusIcon} alt="" />
                Add Section
              </button>
            </div>
          </div>
          <div className="col-md-8 form-content-wrapper-main">
            <div className="form-content-wrapper">
              <div className="form-content-head">
                <div className="d-flex gap-3 align-items-center">
                  <span className="form-content-count">
                    {selectedContent?.section?._id
                      ? addCourse?.courseContents.findIndex(
                          (x) => x._id === selectedContent?.section?._id
                        ) + 1
                      : addCourse?.courseContents.length}
                  </span>
                  <span className="form-content-title">Section</span>
                </div>
                {addCourse.courseContents.length > 1 && (
                  <div
                    className="form-content-remove cursor-pointer"
                    type="button"
                    onClick={() => handleRemove('section')}
                  >
                    <img src={TrashIcon} alt="" />
                  </div>
                )}
              </div>
              <div className="w-100">
                <FormGroup className="w-100">
                  <label className="add_course_form_label my-2 mx-0">
                    Section Title
                  </label>
                  <div className="d-flex align-items-center gap-3">
                    <Input
                      placeholder="Enter section title"
                      type="text"
                      autoComplete="off"
                      className="add_course_form_input"
                      name="title"
                      disabled={
                        selectedContent?.section?.title ? isDisabled : false
                      }
                      valid={isSectionValid}
                      invalid={!isSectionValid}
                      value={selectedContent?.section?.title}
                      onChange={(e) => {
                        setIsSectionValid(!!e.target.value);
                        handleChangeSection('title', e.target.value);
                        setIsDisabled(false);
                      }}
                    />
                    <button
                      className="btn add-section-wrapper"
                      type="button"
                      onClick={handleEditSection}
                    >
                      <CiEdit />
                    </button>
                  </div>
                </FormGroup>
              </div>
            </div>
            <div className="form-content-wrapper">
              <div className="form-content-head">
                <div className="d-flex gap-3 align-items-center">
                  <span className="form-content-count">
                    {selectedContent?.section?.contents &&
                      selectedContent?.section?.contents.findIndex(
                        (x) => x._id === selectedContent?.lecture?._id
                      ) + 1}
                  </span>
                  <span className="form-content-title">Lecture</span>
                </div>
                <div className="d-flex align-items-center flex-row-reverse gap-3">
                  {selectedContent?.section?.contents?.length > 1 && (
                    <div
                      className="form-content-remove"
                      type="button"
                      onClick={() => handleRemove('lecture')}
                    >
                      <img src={TrashIcon} alt="" />
                    </div>
                  )}
                </div>
              </div>
              <Row className="w-100">
                <div className="col-md-6">
                  <FormGroup>
                    <label className="add_course_form_label my-2 mx-0">
                      Media Type
                    </label>
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={mediaOptions}
                      value={mediaOptions.find(
                        (i) => i.value === selectedContent?.lecture.type
                      )}
                      onChange={(newValue) =>
                        handleChangeContents('type', newValue.value)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className="add_course_form_label my-2 mx-0">
                      Free Preview
                    </label>
                    <Checkbox
                      checked={!!selectedContent?.lecture.isFree}
                      onChange={(e) =>
                        handleChangeContents('isFree', e.target.checked)
                      }
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  {(selectedContent?.lecture?.type === 'video' ||
                    selectedContent?.lecture?.type === 'doc') && (
                    <div className="form-content-upload-type">
                      <span
                        className={classNames('form-content-upload-browse', {
                          'active-type': uploadType === 'upload',
                        })}
                        onClick={() => setUploadType('upload')}
                      >
                        Upload
                      </span>
                      <span
                        className={classNames('form-content-upload-browse', {
                          'active-type': uploadType === 'link',
                        })}
                        onClick={() => setUploadType('link')}
                      >
                        Link
                      </span>
                    </div>
                  )}
                </div>
              </Row>
              <Row className="w-100">
                <div
                  className={classNames('col-md-12', {
                    'col-md-6': selectedContent?.lecture?.type === 'video',
                  })}
                >
                  <FormGroup>
                    <label className="add_course_form_label my-2 mx-0">
                      {
                        mediaOptions.find(
                          (item) =>
                            selectedContent?.lecture?.type === item.value
                        )?.label
                      }{' '}
                      Title
                    </label>
                    <Input
                      placeholder={`Enter ${selectedContent?.lecture?.type} title`}
                      type="text"
                      autoComplete="off"
                      className="add_course_form_input"
                      name="title"
                      valid={isLectureValid}
                      invalid={!isLectureValid}
                      value={selectedContent?.lecture?.title}
                      onChange={(e) => {
                        setIsLectureValid(!!e.target.value);
                        handleChangeContents('title', e.target.value);
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  {selectedContent?.lecture?.type === 'video' && (
                    <FormGroup>
                      <label className="add_course_form_label my-2 mx-0">
                        Duration
                      </label>
                      <Input
                        placeholder=""
                        type="number"
                        autoComplete="off"
                        className="add_course_form_input"
                        value={selectedContent?.lecture.duration}
                        onChange={(e) =>
                          handleChangeContents(
                            'duration',
                            parseInt(e.target.value || 0)
                          )
                        }
                      />
                    </FormGroup>
                  )}
                </div>
              </Row>
              {uploadType === 'upload' &&
              ['video', 'doc'].includes(selectedContent?.lecture?.type) ? (
                <FormGroup className="w-100">
                  <label className="add_course_form_label my-2 mx-0">
                    {selectedContent?.lecture?.type}
                  </label>
                  <NewCustomDropzon
                    name="link"
                    accept={
                      mediaTypeExtensionsAccept[selectedContent?.lecture?.type]
                    }
                    handleUpload={handleUpload}
                    type={selectedContent?.lecture?.type}
                  />
                  <div className="d-flex gap-2 my-2">
                    <img src={InfoIcon} alt="" />
                    <span className="add_course_form_sublabel">
                      File formats should be{' '}
                      {mediaTypeExtensions[selectedContent?.lecture?.type]
                        .join(', ')
                        .toUpperCase()}{' '}
                      and size must be less than 200 MB
                    </span>
                  </div>
                  <div className="d-flex flex-column img_preview_wrapper_main">
                    <div className="add_course_form_img_preview_wrapper">
                      <div className="d-flex align-items-center gap-3">
                        <MdVideoFile
                          className="add_course_form_preview_img"
                          style={{ fill: 'var(--primary)' }}
                        />
                        <div className="d-flex flex-column">
                          <span className="add_course_form_img_preview_name">
                            {preview?.name || selectedContent?.lecture?.link}
                          </span>
                          <span className="add_course_form_img_preview_type">
                            {preview?.name?.split('.')[1]?.toUpperCase() ||
                              selectedContent?.lecture?.link
                                ?.split('.')
                                .pop()
                                .toUpperCase()}
                          </span>
                        </div>
                      </div>
                      <span className="add_course_form_img_preview_size">
                        {preview?.size && bytesToSize(preview?.size)}
                      </span>
                    </div>
                    {isStartProgress && (
                      <div className="progress my-3">
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                    )}
                  </div>
                </FormGroup>
              ) : (
                ['video', 'doc'].includes(selectedContent?.lecture?.type) && (
                  <FormGroup className="w-100">
                    <label className="add_course_form_label my-2 mx-0">
                      {
                        mediaOptions.find(
                          (item) =>
                            selectedContent?.lecture?.type === item.value
                        )?.label
                      }{' '}
                      Link
                    </label>
                    <InputGroup className="input-group-merge input-group-alternative input-group-link">
                      <Input
                        placeholder="Add Link"
                        type="text"
                        value={selectedContent?.lecture.link}
                        className="add_course_form_input"
                        onChange={(e) =>
                          handleChangeContents('link', e.target.value)
                        }
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="add_course_form_input">
                          <i className="fas fa-link"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                )
              )}
              {selectedContent?.lecture?.type === 'text' && (
                <FormGroup
                  className="w-100"
                  onClick={() => setQuillChange(true)}
                >
                  <ReactQuill
                    className="react_quill"
                    theme="snow"
                    value={selectedContent?.lecture?.text}
                    onChange={(newValue) => {
                      if (quillChange) handleChangeContents('text', newValue);
                    }}
                    placeholder="Add your course detail.."
                  />
                </FormGroup>
              )}
              {selectedContent?.lecture?.type === 'mcq' && (
                <FormGroup className="w-100">
                  <label className="add_course_form_label my-2 mx-0">
                    Quiz
                  </label>
                  <Select
                    name="mcq"
                    options={mcqLists}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={mcqLists.find(
                      (i) => i.value === selectedContent?.lecture.mcqs?.[0]?._id
                    )}
                    onChange={(newValue) =>
                      handleChangeContents('mcqs', [newValue?.value])
                    }
                  />
                </FormGroup>
              )}
              {selectedContent?.lecture?.type === 'lab' && (
                <FormGroup className="w-100">
                  <label className="add_course_form_label my-2 mx-0">
                    Challenge
                  </label>
                  <Select
                    name="lab"
                    options={labLists}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={labLists.find(
                      (i) =>
                        i.value === selectedContent?.lecture.challengeId?._id
                    )}
                    onChange={(newValue) =>
                      handleChangeContents('challengeId', newValue?.value)
                    }
                  />
                </FormGroup>
              )}
              <div className="d-flex align-items-center justify-content-end w-100">
                {id && (
                  <button
                    className="add_course_save_continue_btn"
                    type="button"
                    onClick={handleSaveChange}
                  >
                    Save
                    <img src={DoubleRightIcon} alt="" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </Row>
        <div className="d-flex justify-content-between mt-5">
          <button className="add_course_form_back_btn" onClick={handleBack}>
            <img src={DoubleLeftIcon} alt="" />
            Back
          </button>
          <button
            className="add_course_form_continue_btn"
            type="button"
            onClick={handleContinue}
          >
            Continue
            <img src={DoubleRightIcon} alt="" />
          </button>
        </div>
      </Form>
      {alert?.status === 'success' && alert?.isOpen && (
        <SweetAlert
          success
          title={<span style={{ fontSize: '24px' }}>{alert?.message}</span>}
          onConfirm={handleConfirm}
        />
      )}
      {alert?.status === 'warning' && alert?.isOpen && (
        <SweetAlert
          warning
          showCancel
          title={<span style={{ fontSize: '24px' }}>{alert?.message}</span>}
          onConfirm={handleDelete}
          onCancel={() => setAlert({ ...alert, isOpen: false })}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  mcqList: state.courses.mcqList?.list,
  challengesList: state.courses.challengesList?.list,
});

const mapDispatchToProps = (dispatch) => ({
  getMCQApi: () => dispatch(getMCQApi()),
  getLabApi: () => dispatch(getLabApi()),
  updateCourseAction: (data, id) =>
    trackPromise(dispatch(updateCourse(data, id)), loaderType.GENERAL_REQUEST),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContent);
