import { Box, MenuItem, Typography } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useEffect, useMemo } from "react";

import {
  deleteOrganisation,
  getAllOrgUser,
  getOrgUsers,
} from "actions/adminActions/organisationsActions";
import { toggleUserStatus } from "actions/adminActions/usersActions";
import { hideAlert, showAlert } from "actions/templateAction";
import classNames from "classnames";
import MuiOverride from "components/Common/MuiOverride";
import ModalLicenseMulti from "components/Modal/ModalLicenseMulti";
import PlusIcon from "iconComponents/PlusIcon";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackPromise } from "react-promise-tracker";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Badge, Card, CardHeader, Container } from "reactstrap";
import { compose } from "redux";
import { loaderType } from "type";
import { assignLicenseToOrg } from "../../../actions/adminActions/plansActions";
import { verifyUserEmail } from "actions/adminActions/usersActions";
import { getAllPlans } from "actions/adminActions/licenseActions";

const Organisations = ({ verifyEmailAction }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orgId, setOrgId] = useState(null);
  const [licenseIds, setLicenseIds] = useState([]);
  const [actionType, setActionType] = useState();
  const users = useSelector((state) => {
    return state.organisation.orgData;
  });
  const [licenseModal, setLicenseModal] = useState(false);

  const plans = useSelector((state) => state.plans.plans);
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);

  useEffect(() => {
    dispatch(getOrgUsers(0));
    dispatch(getAllPlans(0, 0));
    trackPromise(dispatch(getAllOrgUser(0, 2000)), loaderType.GENERAL_REQUEST);
  }, []);

  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => {
              dispatch(getOrgUsers(row?.original._id, 0, 20));
              history.push(`/organisation/org-users/${row?.original._id}`);
            }}
          >
            <span className="avatar rounded-circle mr-3">
              <img
                alt="..."
                src={
                  row?.original?.profilePicUrl
                    ? row?.original?.profilePicUrl
                    : require('assets/img/theme/blank-profile.png')
                }
                style={{ height: '100%', width: 'fit-content' }}
              />
            </span>
            <Typography className="">{row?.original?.name}</Typography>
          </Box>
        ),
      },

      {
        header: 'Email',
        accessorKey: 'email',
        Cell: ({ row }) => (
          <>
            <Typography className="">{row?.original?.email}</Typography>
          </>
        ),
      },
      {
        header: 'Status',
        accessorKey: 'isActive',
        Cell: ({ row }) => (
          <>
            {' '}
            <Badge color="" className="badge-dot mr-4">
              <i
                className={classNames(
                  { 'bg-success': row?.original?.isActive },
                  { 'bg-warning': !row?.original?.isActive }
                )}
              />
              <span
                className={classNames(
                  'status',
                  { 'text-success': row?.original?.isActive },
                  { 'text-warning': !row?.original?.isActive }
                )}
              >
                {row?.original?.isActive ? 'Enabled' : 'Disabled'}
              </span>
            </Badge>
          </>
        ),
      },
      {
        header: 'Licence',
        Cell: ({ row }) => (
          <Typography>
            {row?.original?.liceAssignedDetails?.length ? (
              <>
                {row?.original?.liceAssignedDetails.map((i, index) => {
                  {
                    return (
                      i?.licenseId?.name &&
                      i?.licenseId?.name +
                        (index !==
                        row?.original?.liceAssignedDetails?.length - 1
                          ? ','
                          : '')
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </Typography>
        ),
      },
      {
        header: 'Groups',
        Cell: ({ row }) => <Typography className="">Coming Soon</Typography>,
      },
    ],
    []
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'warning',
      message: 'Are you sure you want to delete?',
    });
  };
  const confirmAlert = () => {
    trackPromise(
      dispatch(deleteOrganisation(orgId, actionType)),
      loaderType.GENERAL_REQUEST
    );

    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'success',
      message: 'Organization deleted successfully.',
    });
    trackPromise(dispatch(getAllOrgUser(0, 2000)), loaderType.GENERAL_REQUEST);
  };
  const handleChangeStatus = (row) => {
    trackPromise(
      dispatch(
        toggleUserStatus({
          uid: row?.original?._id,
          status: !row?.original?.isActive,
        })
      )
        .then((res) => {
          dispatch(showAlert(true, 'success', res.message));
          dispatch(getAllOrgUser(0, 2000));
        })
        .catch((err) => {
          dispatch(showAlert(true, 'warning', err.message));
        })
    );
  };
  const navigateTo = () =>
    type === 'success' ? history.push('/organisations') : null;

  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo('');
    }
  };
  const toggleLicenseModal = (item) => {
    setLicenseModal(!licenseModal);
  };

  const handleAddLicenseIds = (ids) => {
    setLicenseIds(ids);
  };
  const handleUpdateLicense = () => {
    const licenseIdsArray = licenseIds.length
      ? licenseIds.map((i) => i.value)
      : [];
    trackPromise(
      dispatch(assignLicenseToOrg(licenseIdsArray, orgId)),
      loaderType.GENERAL_REQUEST
    )
      .then((res) => {
        dispatch(showAlert(true, 'success', res.message));
        dispatch(getAllOrgUser(0, 2000));

        setLicenseModal(!licenseModal);
      })
      .catch((err) => {
        dispatch(showAlert(true, 'warning', err));
      });
  };
  const handleVerifyEmail = (row) => {
    trackPromise(
      dispatch(
        verifyUserEmail({
          id: row?.original._id,
          email: row.original?.email,
        })
      )
        .then((res) => {
          dispatch(showAlert(true, 'success', res.message));
          dispatch(getOrgUsers(0));
        })
        .catch((err) => {
          dispatch(showAlert(true, 'warning', err.message));
        })
    );
  };
  return (
    <>
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <ModalLicenseMulti
        open={licenseModal}
        plans={plans}
        licenseId={licenseIds}
        setLicenseId={handleAddLicenseIds}
        toggleVisible={toggleLicenseModal}
        handleUpdateLicense={handleUpdateLicense}
      />
      <Container fluid>
        <Card className="">
          <CardHeader>
            <div className="add_course_page_header ">
              <div>
                <h5 className="org_page_title">Organisations</h5>
              </div>

              <div className="d-flex gap-1 align-items-center">
                <button
                  className="pr-btn-with-icon"
                  onClick={() => history.push('/addOrg')}
                >
                  <PlusIcon color="white" />
                  Add Organistation
                </button>
              </div>
            </div>
          </CardHeader>

          <MuiOverride>
            <MaterialReactTable
              columns={columns}
              data={users || plans || []}
              enableRowActions
              enableRowNumbers
              positionActionsColumn="last"
              renderRowActionMenuItems={({ row }) => [
                <>
                  <MenuItem
                    key={1}
                    sx={{ m: 0 }}
                    onClick={() =>
                      history.push(`editOrg/${row?.original?._id}`)
                    }
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    key={1}
                    onClick={() => {
                      setActionType('hard');
                      setOrgId(row?.original?._id);
                      handleDelete();
                    }}
                    sx={{ m: 0 }}
                  >
                    Hard Delete
                  </MenuItem>
                  <MenuItem
                    key={0}
                    onClick={(e) => {
                      let assignLicense = [];
                      if (row?.original?.liceAssignedDetails?.length) {
                        row?.original?.liceAssignedDetails.map((i, index) => {
                          assignLicense.push({
                            label: i?.licenseId?.name,
                            value: i?.licenseId?._id,
                          });
                        });
                      }
                      handleAddLicenseIds(assignLicense);
                      setOrgId(row?.original?._id);
                      toggleLicenseModal(row?.original);
                    }}
                    sx={{ m: 0 }}
                  >
                    Manage License
                  </MenuItem>
                  {!row?.original?.isEmailVerified && (
                    <MenuItem
                      key={1}
                      onClick={() => {
                        setOrgId(row?.original?._id);
                        handleVerifyEmail(row);
                      }}
                      sx={{ m: 0 }}
                    >
                      Verify Email
                    </MenuItem>
                  )}
                  <MenuItem
                    key={1}
                    onClick={() => {
                      handleChangeStatus(row);
                    }}
                    sx={{ m: 0 }}
                  >
                    {!row?.original?.isActive ? 'Enable User' : 'Disable User'}
                  </MenuItem>
                </>,
              ]}
            />
          </MuiOverride>
        </Card>
        {alert.status === 'warning' && alert.isOpenAlert && (
          <SweetAlert
            warning
            title={
              <span
                style={{
                  fontSize: '24px',
                }}
              >
                {alert?.message}{' '}
              </span>
            }
            onConfirm={confirmAlert}
            showCancel
            confirmBtnBsStyle="danger"
            onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
          />
        )}
        {alert.status === 'success' && alert.isOpenAlert && (
          <SweetAlert
            success
            title={
              <span
                style={{
                  fontSize: '24px',
                }}
              >
                {alert?.message}{' '}
              </span>
            }
            onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyEmailAction: (id, email) => {
      trackPromise(
        dispatch(verifyUserEmail({ id, email })),
        loaderType.GENERAL_REQUEST
      );
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Organisations
);
