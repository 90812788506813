import Select from "react-select";
import { getAllCourseCategory } from "actions/adminActions/courseAction";
import { addCoursesCategoryAPI } from "actions/adminActions/courseAction";
import classNames from "classnames";
import InfoIcon from "iconComponents/InfoIcon";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import SweetAlert from "react-bootstrap-sweetalert";
import CategoriesAltImg from "../../../../assets/img/brand/categories.png";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { loaderType } from "type";
import NewCustomDropzon from "components/Common/NewCustomDropzon";
import { useHistory, useParams } from "react-router";
import api2 from "api2";
import axios from "axios";
import { updateCourseCategory } from "actions/adminActions/courseAction";
function AddCourseCategory({
  addCoursesCategoryAction,
  updateCoursesCategoryAction,
}) {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const [categoryName, setCategoryName] = useState();
  const [parentCourseCategory, setParentCourseCategory] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState();
  const [isValidateFiled, setIsValidateFiled] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [showBannerImage, setShowBannerImage] = useState(true);
  const [showIconImage, setShowIconImage] = useState(true);

  const [files, setFiles] = useState();
  const [alert, setAlert] = useState({
    message: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    trackPromise(
      dispatch(getAllCourseCategory(0, 500)),
      loaderType.GENERAL_REQUEST
    );
  }, []);

  const handleChange = (e) => {
    setCategoryName(e.target.value);
  };
  const { categories } = useSelector((state) => state?.categories);
  useEffect(() => {
    trackPromise(
      dispatch(getAllCourseCategory(0, 500)),
      loaderType.GENERAL_REQUEST
    );
  }, []);
  useEffect(() => {
    fetchsingleCategory();
  }, [_id]);
  const fetchsingleCategory = async () => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      let result = await api2.get(`api/courseCategory/${_id}`, config);
      setCategoryName(result?.data?.data?.name);
      setSelectedParentCategory(result?.data?.data?.parent);
      setFiles({
        bannerImage: result?.data?.data?.bannerImageUrl,
        iconImage: result?.data?.data?.iconImageUrl,
      });
      return result.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    let courseCategoryArray = [parentCourseCategory];
    courseCategoryArray =
      categories.length &&
      categories.map((item) => {
        return {
          label: item?.name,
          value: item._id,
        };
      });
    setParentCourseCategory(courseCategoryArray);
  }, [categories]);
  const handleConfirm = () => {
    setAlert({
      ...alert,
      isOpen: false,
    });
    history.push("/course/category");
  };

  const handleAdd = async () => {
    const formData = new FormData();
    if (imageUrl?.bannerImage) {
      formData.append("bannerImageUrl", imageUrl?.bannerImage);
    }
    if (imageUrl?.iconImage) {
      formData.append("iconImageUrl", imageUrl?.iconImage);
    }
    formData.append("name", categoryName);
    if (selectedParentCategory) {
      formData.append("parent", selectedParentCategory);
    }
    _id
      ? await updateCoursesCategoryAction(formData, _id)
      : await addCoursesCategoryAction(formData);
    setAlert({
      isOpen: true,
      status: "success",
      message: _id
        ? "Course Category updated successfully"
        : "Course Category added successfully",
    });
  };
  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "n/a";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + " " + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  }

  const handleUpload = async (file, name) => {
    if (
      file?.name.split(".")[1] === "png" ||
      file?.name.split(".")[1] === "jpg"
    ) {
      setFiles({
        ...files,
        [name]: file,
      });
      try {
        const config = {
          headers: { Authorization: token },
        };
        let formData = new FormData();
        formData.append("file", file);
        let result = await api2.post(
          `api/courseContent/upload`,
          formData,
          config
        );
        setImageUrl({
          ...imageUrl,
          [name]: result?.data?.data?.fileName,
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          throw new Error("Cancelled");
        }
      }
    }
  };
  const handleBannerError = () => {
    setShowBannerImage(false);
  };
  const handleIconError = () => {
    setShowIconImage(false);
  };

  return (
    <Container className="" fluid>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <div className="add_course_page_header">
                  <div className="d-flex gap-3 align-items-center">
                    <h5 className="org_page_title">
                      {_id ? 'Edit' : 'Add'} Course Category
                    </h5>
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <button
                      className="cancel_form_btn"
                      onClick={() => history.push('/course/category')}
                    >
                      Cancel
                    </button>
                    <button className="publish_form_btn" onClick={handleAdd}>
                      {_id ? 'Edit' : 'Add'} Course Category
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form className="needs-validation" noValidate>
                  <Row className="">
                    <div className="col-md-8 mb-3 gap-0 form-content-wrapper-main">
                      <FormGroup className="">
                        <label className="org_form_label my-2 mx-0">
                          Category Name
                        </label>
                        <Input
                          value={categoryName}
                          name="fullName"
                          placeholder="Category Name"
                          autoComplete="off"
                          className="org_form_input"
                          type="text"
                          // valid={state.fullNameErr === "valid"}
                          // invalid={state.fullNameErr === "invalid"}
                          onChange={handleChange}
                        />
                      </FormGroup>

                      <FormGroup className="">
                        <label htmlFor="" className="org_form_label my-2 mx-0">
                          Parent Category
                        </label>
                        <Select
                          className="basic-multi-select"
                          classNamePrefix="select"
                          // options={parentCourseCategory}
                          value={
                            parentCourseCategory.length &&
                            parentCourseCategory.find(
                              (i) => i.value === selectedParentCategory
                            )
                          }
                          onChange={(newValue) => {
                            setSelectedParentCategory(newValue.value);
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: 45,
                            }),
                          }}
                        />
                      </FormGroup>

                      <FormGroup className="">
                        <label className="add_course_form_label my-2 mx-0">
                          Banner Image
                        </label>

                        <NewCustomDropzon
                          name="bannerImage"
                          accept=" image/jpeg, image/jpg, image/png"
                          handleUpload={handleUpload}
                          type="img"
                        />

                        <div className="d-flex gap-2 my-2">
                          <InfoIcon
                            color={
                              isValidateFiled && isValidateFiled?.thumbnail
                                ? '#ff0000'
                                : '#070707'

                              // "#070707"
                            }
                          />
                          <span
                            className={classNames({
                              add_course_form_sublabel: true,
                              'error-message':
                                isValidateFiled && isValidateFiled?.thumbnail,
                            })}
                          >
                            File formats should be PNG or JPG, Max 2MB
                          </span>
                        </div>
                        {files?.bannerImage && (
                          <div className="add_course_form_img_preview_wrapper">
                            <div className="d-flex align-items-center gap-3">
                              {showBannerImage ? (
                                <img
                                  src={
                                    (files?.bannerImage.name &&
                                      URL.createObjectURL(
                                        files?.bannerImage
                                      )) ||
                                    files?.bannerImage
                                  }
                                  className="add_course_form_preview_img"
                                  alt=""
                                  onError={handleBannerError}
                                />
                              ) : (
                                <img
                                  className="add_course_form_preview_img"
                                  src={CategoriesAltImg}
                                  alt=""
                                />
                              )}

                              <div className="d-flex flex-column">
                                <span className="add_course_form_img_preview_name">
                                  {files?.bannerImage?.name ||
                                    files?.bannerImage}
                                </span>
                                <span className="add_course_form_img_preview_type">
                                  {files?.bannerImage?.type === 'image/png' ||
                                  (typeof files?.bannerImage === 'string' &&
                                    files?.bannerImage?.split('.')[1] === 'png')
                                    ? 'PNG'
                                    : 'JPG'}
                                </span>
                              </div>
                            </div>

                            <span className="add_course_form_img_preview_size">
                              {files?.bannerImage?.size &&
                                bytesToSize(files?.bannerImage?.size)}
                            </span>
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup className="">
                        <label className="add_course_form_label my-2 mx-0">
                          Icon Image
                        </label>

                        <NewCustomDropzon
                          name="iconImage"
                          accept=" image/jpeg, image/jpg, image/png"
                          handleUpload={handleUpload}
                          type="img"
                        />

                        <div className="d-flex gap-2 my-2">
                          <InfoIcon
                            color={
                              isValidateFiled && isValidateFiled?.iconImage
                                ? '#ff0000'
                                : '#070707'
                            }
                          />
                          <span
                            className={classNames({
                              add_course_form_sublabel: true,
                              'error-message':
                                isValidateFiled && isValidateFiled?.iconImage,
                            })}
                          >
                            File formats should be PNG or JPG, Max 2MB
                          </span>
                        </div>
                        {files?.iconImage && (
                          <div className="add_course_form_img_preview_wrapper">
                            <div className="d-flex align-items-center gap-3">
                              {showIconImage ? (
                                <img
                                  src={
                                    (files?.iconImage.name &&
                                      URL.createObjectURL(files?.iconImage)) ||
                                    files?.iconImage
                                  }
                                  className="add_course_form_preview_img"
                                  alt=""
                                  onError={handleIconError}
                                />
                              ) : (
                                <img
                                  className="add_course_form_preview_img"
                                  src={CategoriesAltImg}
                                  alt=""
                                />
                              )}

                              <div className="d-flex flex-column">
                                <span className="add_course_form_img_preview_name">
                                  {files?.iconImage?.name || files?.iconImage}
                                </span>
                                <span className="add_course_form_img_preview_type">
                                  {files?.iconImage?.type === 'image/png' ||
                                  (typeof files?.iconImage === 'string' &&
                                    files?.iconImage?.split('.')[1] === 'png')
                                    ? 'PNG'
                                    : 'JPG'}
                                </span>
                              </div>
                            </div>

                            <span className="add_course_form_img_preview_size">
                              {files?.iconImage?.size &&
                                bytesToSize(files?.iconImage?.size)}
                            </span>
                          </div>
                        )}
                      </FormGroup>
                    </div>
                  </Row>

                  {/* <Button color="primary" type="button" onClick={handleAdd}>
                    {_id ? "Edit" : "Add"} Course Category
                  </Button> */}
                </Form>
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
      {alert?.status === 'success' && alert?.isOpen === true && (
        <SweetAlert
          success
          title={
            <span
              style={{
                fontSize: '24px',
              }}
            >
              {alert?.message}{' '}
            </span>
          }
          onConfirm={handleConfirm}
        />
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCoursesCategoryAction: (data, id) =>
      trackPromise(
        dispatch(updateCourseCategory(data, id)),
        loaderType.GENERAL_REQUEST
      ),
    addCoursesCategoryAction: (data) => dispatch(addCoursesCategoryAPI(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCourseCategory);
