import { Box, MenuItem, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useEffect, useMemo } from 'react';

import { getAllOrgUser } from 'actions/adminActions/organisationsActions';
import { deleteTag, getAllTag } from 'actions/adminActions/tagAction';
import { hideAlert } from 'actions/templateAction';
import MuiOverride from 'components/Common/MuiOverride';
import PlusIcon from 'iconComponents/PlusIcon';
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { trackPromise } from 'react-promise-tracker';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, CardHeader, Container } from 'reactstrap';
import { compose } from 'redux';
import { loaderType } from 'type';

const Tags = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [actionType, setActionType] = useState();
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);

  useEffect(() => {
    dispatch(getAllTag());
  }, []);

  const [alert, setAlert] = useState({
    message: '',
    status: '',
    isOpenAlert: false,
  });

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className="">{row?.original?.name}</Typography>
          </Box>
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    setAlert({
      ...alert,
      isOpenAlert: true,
      status: 'warning',
      message: 'Are you sure you want to delete?',
    });
  };
  const confirmAlert = () => {
    trackPromise(
      dispatch(deleteTag(selectedTagId, actionType)),
      loaderType.GENERAL_REQUEST
    )
      .then((res) => {
        dispatch(getAllTag());
        setAlert({
          ...alert,
          isOpenAlert: true,
          status: 'success',
          message: 'Tag deleted successfully.',
        });
      })
      .catch((err) => {
        setAlert({
          ...alert,
          isOpenAlert: true,
          status: 'success',
          message: 'Tag warning successfully.',
        });
      });
  };
  const navigateTo = () =>
    type === 'success' ? history.push('/organisations') : null;

  const handleConfirm = () => {
    const isSuccess = type === 'success';
    dispatch(hideAlert());
    if (isSuccess) {
      navigateTo('');
    }
  };
  return (
    <>
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Container fluid>
        <Card className="">
          <CardHeader>
            <div className="add_course_page_header ">
              <div>
                <h5 className="org_page_title">Tags</h5>
              </div>

              <div className="d-flex gap-1 align-items-center">
                <button
                  className="pr-btn-with-icon"
                  onClick={() => history.push('/add-tag')}
                >
                  <PlusIcon color="white" />
                  Add Tags
                </button>
              </div>
            </div>
          </CardHeader>

          <MuiOverride>
            <MaterialReactTable
              columns={columns}
              data={props.tags || []}
              enableRowActions
              enableRowNumbers
              positionActionsColumn="last"
              renderRowActionMenuItems={({ row }) => [
                <>
                  <MenuItem
                    key={1}
                    sx={{ m: 0 }}
                    onClick={() =>
                      history.push(`edit-tag/${row?.original?._id}`)
                    }
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    key={1}
                    onClick={() => {
                      setActionType('hard');
                      setSelectedTagId(row?.original?._id);
                      handleDelete();
                    }}
                    sx={{ m: 0 }}
                  >
                    Hard Delete
                  </MenuItem>
                </>,
              ]}
            />
          </MuiOverride>
        </Card>
        {alert.status === 'warning' && alert.isOpenAlert && (
          <SweetAlert
            warning
            title={
              <span
                style={{
                  fontSize: '24px',
                }}
              >
                {alert?.message}{' '}
              </span>
            }
            onConfirm={confirmAlert}
            showCancel
            confirmBtnBsStyle="danger"
            onCancel={() => setAlert({ ...alert, isOpenAlert: false })}
          />
        )}
        {alert.status === 'success' && alert.isOpenAlert && (
          <SweetAlert
            success
            title={
              <span
                style={{
                  fontSize: '24px',
                }}
              >
                {alert?.message}{' '}
              </span>
            }
            onConfirm={() => setAlert({ ...alert, isOpenAlert: false })}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.tags.tagData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Tags);
