const initState = {};

const tagReducers = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_TAG_SUCCESS':
      return {
        ...state,
        message: 'Tag created Successfully!',
      };
    case 'UPDATE_TAG_SUCCESS':
      return {
        ...state,
        message: 'Tag updated Successfully!',
      };
    case 'DELETE_TAG_SUCCESS':
      return {
        ...state,
        message: 'Tag deleted Successfully!',
      };
    case 'FETCH_ALL_TAG_SUCCESS':
      return {
        ...state,
        tagData: action.data,
        orgErr: false,
      };
    default:
      return state;
  }
};

export default tagReducers;
