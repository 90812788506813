import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllCategory } from "actions/adminActions/categoriesActions";
import { createLab, editLab, getLab } from "actions/adminActions/labsActions";
import api2 from "api2";
import axios from "axios";
import InfoIcon from "iconComponents/InfoIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackPromise } from "react-promise-tracker";
import ReactQuill from "react-quill";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { loaderType } from "type";
import NewCustomDropzon from "../../../components/Common/NewCustomDropzon";
import PlusIcon from "../../../assets/img/icons/common/icon-plus.svg";
import { getAllTag } from 'actions/adminActions/tagAction';

const difficultyList = [
  {
    value: 'easy',
    label: 'Easy',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'hard',
    label: 'Hard',
  },
];
const AddLabs = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const tagsList = useSelector((state) => state.tags.tagData);
  const token = useSelector((state) => state?.authState?.user?.accessToken);
  const { labData } = useSelector((state) => state?.labs);
  const [alert, setAlert] = useState({
    isOpen: true,
    alertStatus: '',
    messages: '',
  });
  const [categoryList, setCategoryList] = useState();
  const [tagList, setTagList] = useState();
  const [uploadImgUrl, setUploadImgUrl] = useState();
  const [state, setState] = useState({
    title: '',
    titleErr: null,
    content: '',
    contentErr: null,
    instructions: '',
    instructionsErr: null,
    difficulty: '',
    image_tag_id: '',
    image_tag_idErr: null,
    command: '',
    commandErr: null,
    args: '',
    argsErr: null,
    dockerPort: '',
    dockerPortErr: null,
    dockerURL: '',
    dockerURLErr: null,
    difficultyErr: null,
    flagQuestion: [],
    flags: [],
    deferredInstruction: [],
    deferredDelay: [],
    categoryIds: [],
    tagId: null,
    tagIds: [],
    videoURL: '',
    score: 0,
    solution: '',
    solutionErr: '',
    tagIdsErr: '',
    solutionContent: '',
    quizTotal: 0,
    deferredTotal: 0,
    thumbnailImage: '',
  });
  useEffect(() => {
    dispatch(getAllCategory(0, 1000));
    dispatch(getAllTag());
  }, []);
  useEffect(() => {
    const categoryData = categories.map((item) => {
      return {
        id: item?._id,
        label: item?.fullName,
        value: item?._id,
      };
    });
    setCategoryList(categoryData);
  }, [categories]);
  useEffect(() => {
    const tagData =
      tagsList.length &&
      tagsList.map((item) => {
        return {
          id: item?._id,
          label: item?.name,
          value: item?._id,
        };
      });
    setTagList(tagData);
  }, [tagsList]);
  useEffect(() => {
    if (id && labData?.title) {
      setState({
        title: labData?.title,
        content: labData?.content,
        instructions: labData?.instructions,
        difficulty: labData?.difficulty,
        solution: labData?.solution,
        solutionContent: labData?.solutionContent,
        categoryId: labData?.categoryId,
        categoryIds: labData?.categoryIds.map((i) => i._id),
        tagIds: labData?.tagIds?.length && labData?.tagIds.map((i) => i._id),
        score: labData?.score,
        videoURL: labData?.videoURL,
        duration: labData?.duration,
        thumbnailImage: labData?.thumbnailImageUrl,
        flagQuestion: labData?.flagQuestion,
        flags: labData?.flags,
        deferredInstruction: labData?.deferredInstruction,
        deferredDelay: labData?.deferredDelay,
        quizTotal: labData?.flagQuestion.length,
        deferredTotal: labData?.deferredInstruction?.length || 0,
        dockerURL: labData?.docker_url,
        dockerPort: labData?.docker_port,
        args: labData?.args,
        command: labData?.command,
        image_tag_id: labData?.image_tag_id,
      });
    }
  }, [labData]);
  useEffect(() => {
    if (id) {
      dispatch(getLab(id));
    }
  }, [id]);
  function isValidJson(value) {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }
  const validateCustomStylesForm = async () => {
    let newState = state;
    if (newState.title === '') {
      newState.titleErr = 'invalid';
    } else {
      newState.titleErr = 'valid';
    }

    if (newState.content === '') {
      newState.contentErr = 'invalid';
    } else {
      newState.contentErr = 'valid';
    }
    if (newState.dockerURL === '') {
      newState.dockerURLErr = 'invalid';
    } else {
      newState.dockerURLErr = 'valid';
    }
    if (newState.dockerPort === '') {
      newState.dockerPortErr = 'invalid';
    } else {
      newState.dockerPortErr = 'valid';
    }

    if (newState.instructions === '') {
      newState.instructionsErr = 'invalid';
    } else {
      newState.instructionsErr = 'valid';
    }
    if (newState.solution === '') {
      newState.solutionErr = 'invalid';
    } else {
      newState.solutionErr = 'valid';
    }
    if (newState.image_tag_id === '') {
      newState.image_tag_idErr = 'invalid';
    } else {
      newState.image_tag_idErr = 'valid';
    }
    if (newState.command === '') {
      newState.commandErr = 'invalid';
    } else {
      newState.commandErr = 'valid';
    }
    if (newState.args === '') {
      newState.argsErr = 'invalid';
    } else {
      newState.argsErr = 'valid';
    }

    if (newState.flagQuestion === false) {
      newState.flagQuestionErr = 'invalid';
    } else {
      newState.flagQuestionErr = 'valid';
    }

    if (uploadImgUrl) {
      setState({
        ...state,
        thumbnailImageUrl: uploadImgUrl,
      });
    }
    if (!state?.categoryIds?.length) {
      setAlert({
        isOpen: true,
        alertStatus: 'warning',
        messages: 'Please select a category',
      });
      return;
    }
    if (!state?.tagIds?.length) {
      setAlert({
        isOpen: true,
        alertStatus: 'warning',
        messages: 'Please select a Tag',
      });
      return;
    }
    setState({
      ...state,
      ...newState,
    });

    if (
      newState.titleErr === 'invalid' ||
      newState.contentErr === 'invalid' ||
      newState.instructionsErr === 'invalid' ||
      newState.flagQuestionErr === 'invalid' ||
      newState.dockerURLErr === 'invalid' ||
      newState.dockerPortErr === 'invalid'
    ) {
      setState({
        ...state,
        ...newState,
      });
    } else if (id) {
      let toupdatedata = [];
      if (state?.flag?.length && state?.flag?.length > 0) {
        toupdatedata = state;
      } else {
        toupdatedata = state;
        delete toupdatedata['flag'];
      }
      if (uploadImgUrl) {
        toupdatedata['thumbnailImageUrl'] = uploadImgUrl;
      }
      toupdatedata['deferredDelay'] = state?.deferredDelay;
      toupdatedata['deferredInstruction'] = state?.deferredInstruction;
      trackPromise(
        dispatch(
          editLab(id, {
            ...toupdatedata,
            command:
              newState.command && isValidJson(newState.command)
                ? JSON.parse(newState.command)
                : newState.command,
            args:
              newState.args && isValidJson(newState.args)
                ? JSON.parse(newState.args)
                : newState.args,
          })
        ),
        loaderType.GENERAL_REQUEST
      ).then(() => {
        setAlert({
          isOpen: true,
          alertStatus: 'success',
          messages: 'Labs update successfully.',
        });
      });
    } else {
      let obj = { ...state };
      if (uploadImgUrl) {
        obj['thumbnailImageUrl'] = uploadImgUrl;
      }
      const payload = {
        ...obj,
        docker_url: state?.dockerURL,
        docker_port: state?.dockerPort,
        // args: state?.args,
        // command: state?.command,
        image_tag_id: state?.image_tag_id,
        content: state?.content,
        flag: state?.flags,
        flag_question: state?.flagQuestion,
        deferredDelay: state?.deferredDelay,
        deferredInstruction: state?.deferredInstruction,
        instructions: state?.instructions,
        category: categories.find((item) => state?.categoryIds[0] === item?._id)
          ?.shortName,
        difficulty: state?.difficulty,
        score: state?.score,
        solution: state?.solution,
        solutionContent: state?.solutionContent,
        videoURL: state?.videoURL,
        duration: state?.duration,
        categoryId: state.categoryIds[0],
        command:
          newState.command && isValidJson(newState.command)
            ? JSON.parse(newState.command)
            : newState.command,
        args:
          newState.args && isValidJson(newState.args)
            ? JSON.parse(newState.args)
            : newState.args,
      };
      trackPromise(dispatch(createLab(payload)), loaderType.GENERAL_REQUEST)
        .then(async () => {
          setAlert({
            isOpen: true,
            alertStatus: 'success',
            messages: 'Labs added successfully.',
          });
        })
        .catch((err) => {
          setAlert({
            isOpen: true,
            alertStatus: 'warning',
            messages: 'Something went to wrong.',
          });
        });
    }
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = state;
    newState[name] = value;
    if (value === '') {
      newState[name + 'Err'] = 'invalid';
    } else {
      newState[name + 'Err'] = 'valid';
    }
    setState({
      ...state,
      ...newState,
    });
  };

  const handleAddQuiz = () => {
    let newState = { ...state };
    newState.quizTotal++;

    setState({
      ...newState,
    });
  };
  const handleDeferred = () => {
    let newState = { ...state };
    newState.deferredTotal++;

    setState({
      ...newState,
    });
  };

  const handleRemoveDeferred = (i) => {
    let newState = state;
    newState.deferredTotal--;
    newState.deferredInstruction.pop();
    newState.deferredDelay.pop();

    setState({
      ...newState,
    });
  };
  const handleRemoveQuiz = (i) => {
    let newState = state;
    newState.quizTotal--;
    newState.flagQuestion.pop();
    newState.flags.pop();

    setState({
      ...newState,
    });
  };

  function addQuizHandler(e, stateName, key) {
    let newState = state;
    let questions = newState.flagQuestion;
    let answers = newState.flags;
    let isQUestion = stateName === 'flagQuestion' ? true : false;

    if (isQUestion) {
      questions[key] = e.target.value;
    }
    if (!isQUestion) {
      answers[key] = e.target.value;
    }

    if (isQUestion) {
      setState({
        ...state,
        flagQuestion: questions,
      });
    } else {
      setState({
        ...state,
        flag: answers,
      });
    }
  }
  function addDeferredHandler(e, stateName, key) {
    let newState = state;
    let instruction = newState.deferredInstruction || [];
    let delay = newState.deferredDelay || [];
    let isQUestion = stateName === 'deferredInstruction' ? true : false;

    if (isQUestion) {
      instruction[key] = e;
    }
    if (!isQUestion) {
      delay[key] = e.target.value;
    }

    if (isQUestion) {
      setState({
        ...state,
        deferredInstruction: instruction,
      });
    } else {
      setState({
        ...state,
        deferredDelay: delay,
      });
    }
  }
  const handleUpload = async (file) => {
    if (
      file?.name.split('.')[1] === 'png' ||
      file?.name.split('.')[1] === 'jpg'
    ) {
      setState({
        ...state,
        thumbnailImage: file,
      });
      try {
        const config = {
          headers: { Authorization: token },
        };
        let formData = new FormData();
        formData.append('file', file);
        let result = await api2.post(`api/lab/upload`, formData, config);
        setUploadImgUrl(result?.data?.data?.fileName);
      } catch (error) {
        if (axios.isCancel(error)) {
          throw new Error('Cancelled');
        }
      }
    }
  };
  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }
  const handleSuccess = () => {
    setAlert({
      isOpen: false,
      alertStatus: '',
      messages: '',
    });
    history.push('/labs');
  };
  const handleWarning = () => {
    setAlert({
      isOpen: false,
      alertStatus: '',
      messages: '',
    });
  };

  return (
    <>
      <SimpleHeader name="Add User" parentName="Users" />
      {alert?.alertStatus === 'success' && alert.isOpen && (
        <SweetAlert success title={alert?.messages} onConfirm={handleSuccess} />
      )}
      {alert?.alertStatus === 'warning' && alert.isOpen && (
        <SweetAlert warning title={alert?.messages} onConfirm={handleWarning} />
      )}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title mb-0">
                        {id ? 'Edit' : 'Add'} Lab
                      </h5>
                    </div>

                    <div className="d-flex gap-1 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push('/labs')}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        onClick={() => {
                          validateCustomStylesForm();
                        }}
                      >
                        {id ? 'Save' : 'Add'} Lab
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation">
                    <div className="form-row">
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Title
                          </label>
                          {console.log('state', state)}
                          <Input
                            value={labData?.name || state?.title}
                            name="title"
                            autoComplete="off"
                            placeholder="Title"
                            className="org_form_input"
                            type="text"
                            valid={state.titleErr === 'valid'}
                            invalid={state.titleErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Content
                          </label>
                          <Input
                            value={state.content}
                            name="content"
                            autoComplete="off"
                            placeholder="Content"
                            className="org_form_input"
                            type="text"
                            valid={state.contentErr === 'valid'}
                            invalid={state.contentErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Instructions
                          </label>
                          <Input
                            value={state.instructions}
                            name="instructions"
                            autoComplete="off"
                            placeholder="Instructions"
                            className="org_form_input"
                            type="text"
                            valid={state.instructionsErr === 'valid'}
                            invalid={state.instructionsErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Difficulty
                          </label>
                          <Select
                            value={difficultyList.find(
                              (item) => item.value === state.difficulty
                            )}
                            name="difficultyList"
                            options={difficultyList}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                minHeight: '45px',
                                border: state.categoryIdsErr
                                  ? '1px solid red'
                                  : '1px solid #d2d6da',
                              }),
                            }}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setState({
                                ...state,
                                difficulty: e.value,
                              });
                            }}
                          />
                        </FormGroup>
                        {/* <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Difficulty
                          </label>
                          <Input
                            value={state.difficulty}
                            name="difficulty"
                            placeholder="Difficulty"
                            className="org_form_input"
                            type="select"
                            valid={state.difficultyErr === "valid"}
                            invalid={state.difficultyErr === "invalid"}
                            onChange={customStylesForm}
                          >
                            <option value="" selected disabled>
                              Select Difficulty
                            </option>
                            <option value="easy">Easy</option>
                            <option value="medium">Medium</option>
                            <option value="hard">Hard</option>
                          </Input>
                        </FormGroup> */}
                      </Col>
                      <Col className="mb-1" md="6">
                        <Col className="mb-1 px-0" md="12">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Solution Link
                            </label>
                            <InputGroup className="input-group-merge input-group-alternative input-group-link ">
                              <Input
                                value={state.solution}
                                name="solution"
                                placeholder="Solution"
                                className="org_form_input"
                                type="text"
                                valid={state.solutionErr === 'valid'}
                                invalid={state.solutionErr === 'invalid'}
                                onChange={customStylesForm}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText className="add_course_form_input">
                                  <i className="fas fa-link"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col className="mb-1 px-0" md="12">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Category
                            </label>
                            <Select
                              isMulti
                              value={
                                categoryList?.length &&
                                categoryList.filter((item) =>
                                  state?.categoryIds?.includes(item?.value)
                                )
                              }
                              name="categoryIds"
                              options={categoryList}
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  minHeight: '45px',
                                  border: state.categoryIdsErr
                                    ? '1px solid red'
                                    : '1px solid #d2d6da',
                                }),
                              }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                let newState = state;
                                if (e.length > 0) {
                                  newState.categoryIdsErr = false;
                                } else {
                                  newState.categoryIdsErr = true;
                                }
                                setState({
                                  ...state,
                                  categoryIds: e.map((item) => item?.value),
                                });
                              }}
                            />
                          </FormGroup>

                          {state.categoryIdsErr && (
                            <div style={{ color: 'red' }}>
                              Please select category
                            </div>
                          )}
                        </Col>
                        <Col className="mb-1 px-0" md="12">
                          {console.log('state?.tagIds', state?.tagIds)}
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Tags
                            </label>
                            <Select
                              isMulti
                              value={
                                tagList?.length &&
                                tagList.filter((item) =>
                                  state?.tagIds?.includes(item?.value)
                                )
                              }
                              name="tagIds"
                              options={tagList}
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  minHeight: '45px',
                                  border: state.tagIdsErr
                                    ? '1px solid red'
                                    : '1px solid #d2d6da',
                                }),
                              }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                let newState = state;
                                if (e.length > 0) {
                                  newState.tagIdsErr = false;
                                } else {
                                  newState.tagIdsErr = true;
                                }
                                setState({
                                  ...state,
                                  tagIds: e.map((item) => item?.value),
                                });
                              }}
                            />
                          </FormGroup>

                          {state.categoryIdsErr && (
                            <div style={{ color: 'red' }}>
                              Please select category
                            </div>
                          )}
                        </Col>
                        <Col className="mb-1 px-0" md="12">
                          <FormGroup className="">
                            <label className="org_form_label my-2 mx-0">
                              Points
                            </label>
                            <Input
                              value={state.score}
                              name="score"
                              placeholder="Points"
                              className="org_form_input"
                              type="number"
                              valid={state.score === 'valid'}
                              invalid={state.score < 0}
                              onChange={customStylesForm}
                            />
                          </FormGroup>
                        </Col>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Solution Content
                          </label>

                          <ReactQuill
                            theme="snow"
                            placeholder="Add your course detail.."
                            value={state?.solutionContent}
                            onChange={(value) => {
                              if (state.title) {
                                setState({
                                  ...state,
                                  solutionContent: value,
                                });
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Video Url
                          </label>
                          <InputGroup className="input-group-merge input-group-alternative input-group-link ">
                            <Input
                              value={state.videoURL}
                              name="videoURL"
                              placeholder="Video Url"
                              className="org_form_input"
                              type="url"
                              valid={state.videoURL === 'valid'}
                              invalid={state.videoURL === 'invalid'}
                              onChange={customStylesForm}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText className="add_course_form_input">
                                <i className="fas fa-link"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Duration
                          </label>
                          <Input
                            value={state.duration}
                            name="duration"
                            placeholder="duration"
                            className="org_form_input"
                            type="number"
                            valid={state.duration === 'valid'}
                            invalid={state.duration < 0}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Docker URL
                          </label>
                          <Input
                            value={state.dockerURL}
                            name="dockerURL"
                            autoComplete="off"
                            placeholder="Docker URL"
                            className="org_form_input"
                            type="text"
                            valid={state.dockerURLErr === 'valid'}
                            invalid={state.dockerURLErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Docker Port
                          </label>
                          <Input
                            value={state.dockerPort}
                            name="dockerPort"
                            autoComplete="off"
                            placeholder="Docker Port"
                            className="org_form_input"
                            type="text"
                            valid={state.dockerPortErr === 'valid'}
                            invalid={state.dockerPortErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            args
                          </label>
                          <Input
                            value={state.args}
                            name="args"
                            autoComplete="off"
                            placeholder="Args"
                            className="org_form_input"
                            type="text"
                            valid={state.argsErr === 'valid'}
                            invalid={state.argsErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            command
                          </label>
                          <Input
                            value={state.command}
                            name="command"
                            autoComplete="off"
                            placeholder="Command"
                            className="org_form_input"
                            type="text"
                            valid={state.commandErr === 'valid'}
                            invalid={state.commandErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="6">
                        <FormGroup className="">
                          <label className="org_form_label my-2 mx-0">
                            Image Tag Id
                          </label>
                          <Input
                            value={state.image_tag_id}
                            name="image_tag_id"
                            placeholder="Image Tag Id"
                            className="org_form_input"
                            type="text"
                            valid={state.image_tag_idErr === 'valid'}
                            invalid={state.image_tag_idErr === 'invalid'}
                            onChange={customStylesForm}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mb-1" md="12">
                        <FormGroup className="">
                          <label className="add_course_form_label my-2 mx-0">
                            Icon Image
                          </label>

                          <NewCustomDropzon
                            accept=" image/jpeg, image/jpg, image/png"
                            handleUpload={handleUpload}
                            type="img"
                          />

                          <div className="d-flex gap-2 my-2">
                            <InfoIcon
                              color={
                                // isValidateFiled && isValidateFiled?.iconImage
                                //   ? "#ff0000"
                                '#070707'
                              }
                            />
                            <span
                              className="add_course_form_sublabel"
                              // className={classNames({
                              //   add_course_form_sublabel: true,
                              //   "error-message":
                              //     isValidateFiled &&
                              //     isValidateFiled?.iconImage,
                              // })}
                            >
                              File formats should be PNG or JPG, Max 2MB
                            </span>
                          </div>
                          {state?.thumbnailImage && (
                            <div className="add_course_form_img_preview_wrapper">
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={
                                    (state?.thumbnailImage?.name &&
                                      URL.createObjectURL(
                                        state?.thumbnailImage
                                      )) ||
                                    state?.thumbnailImage
                                  }
                                  className="add_course_form_preview_img"
                                  alt=""
                                />

                                <div className="d-flex flex-column">
                                  <span className="add_course_form_img_preview_name">
                                    {state?.thumbnailImage?.name ||
                                      state?.thumbnailImage}
                                  </span>
                                  <span className="add_course_form_img_preview_type">
                                    {state?.thumbnailImage?.type ===
                                      'image/png' ||
                                    (typeof thumbnailImage === 'string' &&
                                      state?.thumbnailImage?.split('.')[1] ===
                                        'png')
                                      ? 'PNG'
                                      : 'JPG'}
                                  </span>
                                </div>
                              </div>

                              <span className="add_course_form_img_preview_size">
                                {state?.thumbnailImage?.size &&
                                  bytesToSize(state?.thumbnailImage?.size)}
                              </span>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </div>
                    {/* {state.videoURL !== "" ? (
                        <Col className="mb-5" md="6">
                          <ReactPlayer rel="0" url={state.videoURL} />
                        </Col>
                      ) : (
                        <Col className="mb-1" md="6">
                          <p>Enter url to preview</p>
                        </Col>
                      )} */}
                    <div>
                      <label className="add_course_form_label my-2 mx-0">
                        Quiz
                      </label>

                      {state.quizTotal < 1 ? (
                        <></>
                      ) : (
                        <>
                          {Array.from(Array(state?.quizTotal).keys()).map(
                            (x, i) => {
                              return (
                                <Row key={i} className="align-items-end">
                                  <Col className="mb-1" md="4">
                                    <label
                                      className="org_form_label my-2 mx-0"
                                      htmlFor={`quizQuestValidateCustom${i}`}
                                    >
                                      Question {i + 1}
                                    </label>
                                    <Input
                                      value={
                                        state.flagQuestion &&
                                        state.flagQuestion[i]
                                      }
                                      id={`quizQuestValidateCustom${i}`}
                                      placeholder="Add Question"
                                      onChange={(e) =>
                                        addQuizHandler(e, 'flagQuestion', i)
                                      }
                                    />
                                  </Col>
                                  <Col className="mb-1" md="4">
                                    <label
                                      className="org_form_label my-2 mx-0"
                                      htmlFor={`quizAnswValidateCustom${i}`}
                                    >
                                      Answer {i + 1}
                                    </label>
                                    <Input
                                      // value={
                                      //   (state.flag && state.flag[i]) || ""
                                      // }
                                      value={
                                        state.flags[0]?.flag &&
                                        state.flags[0]?.flag[i]
                                      }
                                      id={`quizAnswValidateCustom${i}`}
                                      placeholder="Add Answer"
                                      onChange={(e) => {
                                        addQuizHandler(e, 'flags', i);
                                      }}
                                    />
                                  </Col>

                                  <Col className="mb-1" md="4">
                                    <Button
                                      color="danger"
                                      className="mb-1"
                                      type="button"
                                      onClick={() => handleRemoveQuiz(i + i)}
                                    >
                                      Remove Question
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            }
                          )}
                        </>
                      )}
                      <Row>
                        <Col md={8}>
                          <div
                            className="course_add_requirement_input"
                            onClick={() => {
                              setState({
                                ...state,
                                quizTotal: state.quizTotal + 1,
                              });
                              handleAddQuiz();
                            }}
                          >
                            <img src={PlusIcon} alt="" />
                            <span className="course_add_requirement_title">
                              Add Quiz{' '}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <label className="add_course_form_label my-2 mx-0">
                        hints
                      </label>
                      {state.deferredTotal < 1 ? (
                        <></>
                      ) : (
                        <>
                          {Array.from(Array(state?.deferredTotal).keys()).map(
                            (x, i) => {
                              return (
                                <div key={i}>
                                  <Row className="align-items-end">
                                    <Col className="mb-1" md="8">
                                      <label
                                        className="org_form_label my-2 mx-0"
                                        htmlFor={`quizAnswValidateCustom${i}`}
                                      >
                                        Delay {i + 1} (Minutes)
                                      </label>
                                      <Input
                                        type="number"
                                        value={
                                          state.deferredDelay &&
                                          state.deferredDelay[i]
                                        }
                                        id={`quizAnswValidateCustom${i}`}
                                        placeholder="Add Delay"
                                        onChange={(e) => {
                                          addDeferredHandler(
                                            e,
                                            'deferredDelay',
                                            i
                                          );
                                        }}
                                      />
                                    </Col>

                                    <Col className="mb-1" md="4">
                                      <Button
                                        color="danger"
                                        className="mb-1"
                                        type="button"
                                        onClick={() =>
                                          handleRemoveDeferred(i + i)
                                        }
                                      >
                                        Remove hint
                                      </Button>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-top">
                                    <Col className="mb-1" md="8">
                                      <label
                                        className="org_form_label my-2 mx-0"
                                        htmlFor={`quizQuestValidateCustom${i}`}
                                      >
                                        hint {i + 1}
                                      </label>
                                      <ReactQuill
                                        theme="snow"
                                        placeholder="Add your course detail.."
                                        value={
                                          state.deferredInstruction &&
                                          state.deferredInstruction[i]
                                        }
                                        onChange={(value) =>
                                          addDeferredHandler(
                                            value,
                                            'deferredInstruction',
                                            i
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                      <Row>
                        <Col md={8}>
                          <div
                            className="course_add_requirement_input"
                            onClick={() => {
                              setState({
                                ...state,
                                deferredTotal: state.deferredTotal + 1 || 1,
                              });
                              handleDeferred();
                            }}
                          >
                            <img src={PlusIcon} alt="" />
                            <span className="course_add_requirement_title">
                              Add Hint Point
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    {/* <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        validateCustomStylesForm();
                      }}
                    >
                      {id ? "Save" : "Add"} Labs
                    </Button> */}
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddLabs;
